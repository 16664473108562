import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import axios from 'axios';

import Layout from '../components/Layout';
import useCommit from '../utils/useCommit';

const UserRole = (props) => {
  const [permissions, setPermissions] = useState([]);
  const [userName, setUserName] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [permissionList, setPermissionList] = useState([]);
  const { scrollToTop, setIsCommitting, FormSubmit, setMessage, AlertMessage } = useCommit();

   useEffect(() => {
     setIsLoaded(false);
     const id = props.match.params.id;
     axios.get('/api/partners/user-permissions/'+id)
        .then(res => {
          setUserName(res.data.userName);
          const perms = res.data.permissions;
          const checkItems = [];
          for (let i = 0; i < perms.length; i++) {
            if (perms[i].checked === 1) {
              checkItems.push(perms[i]);
            }
          }
          setPermissionList(perms);
          setPermissions(checkItems);
          setIsLoaded(true);
        }).catch(error => {
          console.log(error)
        });
    
    // eslint-disable-next-line
   }, []);

  const handleChange = (event) => {
    const index = permissionList.findIndex(element => element.id === parseInt(event.target.id));
    let newPermissionList = [...permissionList];
    newPermissionList[index] = {...newPermissionList[index], checked: newPermissionList[index].checked === 1 ? 0 : 1};
    setPermissionList(newPermissionList);

    if (newPermissionList[index].checked === 1) {
      setPermissions([
        ...permissions,
        newPermissionList[index]
      ])
    } else {
      const restItems = permissions.filter(perms => perms.id !== newPermissionList[index].id);
      setPermissions(restItems);
    }
  }
  const formSubmit = () => {
    if (permissions.length > 0)
    {
      setIsCommitting(true);
      const id = props.match.params.id;
      const data = {
        params: permissions
      }
      axios.post('/api/partners/user-permissions/update/' + id, data).then(res => {
        setMessage({
          type: res.data.status,
          text: res.data.message,
        });
        setIsCommitting(false);
        scrollToTop();
      }).catch();
    }
  }
  return (
    <Layout dataIsLoaded={isLoaded}>
      <div className="Dashboard-ContentBody__Header">
        <Row>
          <Col md="12">
            <h1 className="Dashboard-ContentBody__Header--Title">
              Permissions
            </h1>
            < AlertMessage / >
          </Col>
        </Row>
      </div>
      <div className="Dashboard-ContentBody__TableData">
        <Row>
          <Col>
            <Card className="shadow-sm border-0">
              <Card.Body className="bg-white pt-4">
                <h5 className="text-dark mb-3">Name: <span className="font-weight-bold">{userName}</span></h5>
                <Row>
                  {
                    permissionList.map(perm => (
                      <Col md="4" key={perm.id}>
                        <Form.Check 
                          type="switch"
                          id={perm.id}
                          label={perm.display_name}
                          checked={perm.checked === 1 ? true : false}
                          className="mb-2"
                          onChange={handleChange}
                        />
                      </Col>
                    ))
                  }
                </Row>
                < FormSubmit
                    type = "button"
                    variant = "primary"
                    btnText = "Save Changes" 
                    className = "mt-2"
                    onClick={ formSubmit }
                  />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}
 
export default UserRole;