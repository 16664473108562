import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import axios from 'axios';

import Layout from '../components/Layout';
import { InputField, InputSelect } from '../components/common';
import { handleNumbersOnlyKeyDown } from '../utils';
import { stateOptions } from '../staticData';
import useForm from '../components/form/useForm';
import validate from '../components/form/ProfileValidation';
import useCommit from '../utils/useCommit';

const ProfileEdit = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { scrollToTop, setIsCommitting, FormSubmit, setMessage, AlertMessage } = useCommit();
  
  const updateProfile = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      setIsCommitting(true);
      const { first_name, last_name, email, phone, businessName, address, city, state, zipcode } = values;
      const data = {
        first_name,
        last_name,
        email,
        phone,
        business_name: businessName,
        street: address,
        city,
        state,
        post_code: zipcode
      };
      axios.post('/api/customers/profile-update', data)
        .then(res => {
          setMessage({
            type: 'success',
            text: 'Profile has been updated successfully!'
          });
          setIsCommitting(false);
          scrollToTop();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  const { 
    values, 
    errors, 
    handleChange, 
    handleSelectChange,
    handleSubmit 
  } = useForm(updateProfile, validate);

  useEffect(() => {
    axios.get('/api/customers/profile-info')
      .then(res => {
         const data = {
           first_name: res.data.data.first_name,
           last_name: res.data.data.last_name,
           email: res.data.data.email,
           phone: res.data.data.phone,
           cust_type: res.data.data.cust_type,
           businessName: res.data.data.business_name,
           address: res.data.data.street,
           city: res.data.data.city,
           state: res.data.data.state,
           zipcode: res.data.data.post_code
         };
         if (data !== undefined) {
           const keys = Object.keys(data);
           for (let i = 0; i < keys.length; i++) {
             values[keys[i]] = data[keys[i]];
           }
         };
        setIsLoaded(true);
      })
      .catch(error => {
        console.log(error);
      });
  
    // eslint-disable-next-line
  }, []);

  return (
    <Layout dataIsLoaded={isLoaded}>
      <div className="Dashboard-ContentBody__Header">
        <h1 className="Dashboard-ContentBody__Header--Title">
          Edit Profile
        </h1>
      </div>
      <div className="Dashboard-ContentBody__Form">
        <Row>
          <Col md="10">
            <AlertMessage />
            <Card className="shadow-sm">
              <Card.Body>
                <Form onSubmit={handleSubmit} noValidate>
                  <h5 className="text-secondary mb-3">General information</h5>
                  <Row>
                    <Col md="6">
                      <InputField
                        type="text"
                        name = "first_name"
                        label="First Name"
                        value={values.first_name || ''}
                        onChange={handleChange}
                        errorMsg={errors.first_name}
                        required
                      />
                    </Col>
                    <Col md="6">
                      <InputField
                        type="text"
                        name="last_name"
                        label="Last Name"
                        value={values.last_name || ''}
                        onChange={handleChange}
                        errorMsg={errors.last_name}
                        required
                      />
                    </Col>
                    <Col md="6">
                      <InputField
                        type="email"
                        name="email"
                        label="Email address"
                        value={values.email || ''}
                        onChange={handleChange}
                        errorMsg={errors.email}
                        required
                      />
                    </Col>
                    <Col md="6">
                      <InputField
                        type="text"
                        name="phone"
                        label="Phone number"
                        maxLength={10}
                        value={values.phone || ''}
                        onChange={handleChange}
                        onKeyDown={handleNumbersOnlyKeyDown}
                        errorMsg={errors.phone}
                        required
                      />
                    </Col>
                  </Row>
                  <h5 className="text-secondary mt-5 mb-3">Business information</h5>
                  <Row>
                   {
                     values.cust_type === "Business" &&
                    <Col md="12">
                      <InputField
                        type="text"
                        name="businessName"
                        label="Business name"
                        value={values.businessName || ''}
                        onChange={handleChange}
                        errorMsg={errors.businessName}
                        required
                      />
                    </Col>
                   }
                    <Col md="12">
                      <InputField
                        type="text"
                        name="address"
                        label="Address"
                        placeholder="Street address, P.O. Box"
                        value={values.address || ''}
                        onChange={handleChange}
                        errorMsg={errors.address}
                        required
                      />
                    </Col>
                    <Col md="4">
                      <InputField
                        type="text"
                        name="city"
                        label="City"
                        value={values.city || ''}
                        onChange={handleChange}
                        errorMsg={errors.city}
                        required
                      />
                    </Col>
                    <Col md="4">
                      <InputSelect
                        name="state"
                        label="State"
                        placeholder="Make a selection"
                        options={stateOptions}
                        value={stateOptions.find(item => item.value === values.state)}
                        onChange={(event) => handleSelectChange(event.value, 'state')}
                        errorMsg={errors.state}
                        required={true}
                      />
                    </Col>
                    <Col md="4">
                      <InputField
                        type="text"
                        name="zipcode"
                        label="Zip code"
                        value={values.zipcode || ''}
                        onChange={handleChange}
                        errorMsg={errors.zipcode}
                        required
                      />
                    </Col>
                  </Row>
                  <FormSubmit
                    type="submit"
                    variant="primary"
                    btnText="Save Changes"
                  />
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default ProfileEdit;