import React, { useState } from 'react';
import { Container, Row, Col, Image, Card, Form } from 'react-bootstrap';
import axios from 'axios';

import logo from '../assets/images/logo.png';
import useCommit from '../utils/useCommit';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { setIsCommitting, FormSubmit, setMessage, AlertMessage } = useCommit();


  const handleChangeEmail = (event) => {
    const value = event.target.value;

    if (value) {
      setEmail(value);
      setError('');
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email) {
      setIsCommitting(true);
      axios.post('/api/customers/forget-password',{
        email: email
      }).then(res =>{
        setIsCommitting(false);
        if (res.data.status === 'success')
        {
          setMessage({
            type: 'success',
            text: res.data.message
          });
        }else
        {
          setMessage({
            type: 'danger',
            text: res.data.message
          });
        }
      }).catch(error => {

      });
    } else {
      setError('Email address is required!');
    }
  }

  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      <div className="Dashboard-ContentBody__Form Dashboard-ContentBody__Form--SignIn">
        <Container>
          <Row>
            <Col>
              <div className="text-center mt-4 mb-4">
                <a href="/" className="Dashboard-Header__Brand">
                  <Image fluid src={logo} alt="Geeks Sydney" />
                </a>
              </div>
              <Card className="border-0 mx-auto shadow" style={{ maxWidth: '450px' }}>
                <Card.Body>
                  <AlertMessage />
                  <Form onSubmit={handleSubmit} noValidate>
                    <Form.Group controlId="emailAddress">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control 
                        type="email" 
                        placeholder="Enter email" 
                        value={email}
                        onChange={handleChangeEmail}
                        isInvalid={!!error}
                      />
                      <Form.Control.Feedback type="invalid">
                        {error}
                      </Form.Control.Feedback>
                      <Form.Text className="text-muted">
                        Enter your account's email address and we will send you a password reset link.
                      </Form.Text>
                    </Form.Group>
                    <FormSubmit
                      block
                      type="submit"
                      variant="primary"
                      btnText="Send password reset email"
                    />
                  </Form>
                </Card.Body>
              </Card>
              <p className="text-center mt-3">&copy; {new Date().getFullYear()} Geeks Sydney Pty. Ltd.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
 
export default ForgetPassword;