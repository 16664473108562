import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Badge, Dropdown, Modal, Form, Button } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { useAuth } from '../context/auth';

import Layout from '../components/Layout';
// import TablePagination from '../../../components/TablePagination';
import { searchStatusOptions } from '../staticData';
import useCommit from '../utils/useCommit';

const Users = () => {
  const { auth } = useAuth();
  const permissions = auth.permission;
  const [userId, setUserId] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [userList, setUserList] = useState([]);
  const { scrollToTop, setMessage, AlertMessage } = useCommit();
  const [contact, setContact] = useState({
    keyword: '',
    status: null
  });
  const [showUserModal, setShowUserModal] = useState(false);

  const handleCloseUserModal = () => setShowUserModal(false);
  const handleShowUserModal = (id) => {
    setUserId(id);
    setShowUserModal(true);
  }


  const handleSubmit = ( e ) => {
    e.preventDefault();
    let sts;
    if (contact.status) {
      sts = contact.status.value;
    } else {
      sts = contact.status;
    }

    const data = {
      keyword: contact.keyword,
      status: sts,
    }
    axios.post('/api/partners/alias-user/list/search', data)
    .then(res => {
        setUserList(res.data.users);

      }).catch(error => {
        console.log(error)
      });
  }
   useEffect(() => {
     setIsLoaded(false);
     userData();
   }, []);

   const handleDeleteUserData = (id) =>{
      axios.get('/api/partners/alias-user/delete/'+id).then(res => {
        userData();
        setShowUserModal(false);
        setMessage({
          type: res.data.status,
          text: res.data.message,
        });
        scrollToTop();
      }).catch(error => {
        console.log(error);
      });
   }

   const userData = () =>{
    axios.get('/api/partners/alias-user/list')
      .then(res => {
        setUserList(res.data.users);
        setIsLoaded(true);
      }).catch(error => {
        console.log(error)
      });

   }

  return (
    <Layout dataIsLoaded={isLoaded}>
      <div className="Dashboard-ContentBody__Header">
        <Row>
          <Col md="12">
            < AlertMessage />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <h1 className="Dashboard-ContentBody__Header--Title">
              Manage Users
            </h1>
          </Col>
          { permissions.find(item => item === "partner-user-create") &&
          <Col md="6">
            <div className="text-md-right">
              <Link 
                to="/user/new"
                className="btn btn-primary"
              >Add User</Link>
            </div>
          </Col>
          }
        </Row>
      </div>
      <div className="Dashboard-ContentBody__TableData">
        <Row>
          <Col>
            <Card className="shadow-sm border-0">
              <Card.Header className="bg-white pt-4">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="5">
                      <Form.Group controlId="formSearch">
                        <Form.Label className="sr-only">Search</Form.Label>
                        <Form.Control 
                          type="text" 
                          placeholder="Search for a user"
                          onChange={(e) => setContact({ ...contact, keyword: e.target.value })} 
                        />
                      </Form.Group>
                    </Col>
                    <Col md="5">
                      <Form.Group controlId="formStatus">
                        <Form.Label className="sr-only">Status</Form.Label>
                        <Select 
                          instanceId="formStatus"
                          classNamePrefix="NextSelect"
                          placeholder="Select a status"
                          options={searchStatusOptions}
                          onChange={(value) => setContact({ ...contact, status: value })}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Button
                        type="submit"
                        variant="secondary"
                      >Search</Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Header>
              <Card.Body>
                <Table>
                  <colgroup>
                    <col width="auto" />
                    <col width="30%" />
                    <col width="15%" />
                    <col width="15%" />
                    <col width="100px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th className="tableNav">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      userList.map(user=>(
                        <tr key={ user.id }>
                          <td>
                          <strong>{ user.name }</strong>
                          </td>
                          <td>{ user.email }</td>
                          <td>{ user.role }</td>
                          <td>
                            <Badge
                              variant = { user.status === 1 ? 'success' : 'danger' }
                            >{ user.status === 1 ? 'Active' : 'Deactive' }</Badge>
                          </td>
                          <td className="tableNav">
                            <Dropdown>
                              <Dropdown.Toggle id="table-more">
                                <MoreVertical size="18" />
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="shadow border-0" alignRight>
                                { permissions.find(item => item === "partner-user-read") &&
                                <Link 
                                  to={"user/add/"+user.id}
                                  className="dropdown-item"
                                >Edit</Link>
                                }
                                { permissions.find(item => item === "partner-user-update") &&
                                <Link 
                                  to="#"
                                  onClick={() => handleShowUserModal(user.id)}
                                  className="dropdown-item"
                                >Delete</Link>
                                }
                                { permissions.find(item => item === "partner-user-delete") &&
                                <Link 
                                  to={"user/permissions/"+user.id}
                                  className="dropdown-item"
                                >Permissions</Link>
                                }
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    }
                    
                  </tbody>
                </Table>
                {/* <TablePagination 
                  total={5}
                  active={2}
                /> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal 
        centered
        size="sm"
        show={showUserModal} 
        dialogClassName="Dashboard-ContentBody__RequestUserModal"
        onHide={handleCloseUserModal}
      >
        <Modal.Body className="text-center">
          <h1 className="text-danger">Are you sure?</h1>
          <p className="text-secondary small">Do you really want to delete this record? This process cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary" 
            onClick={ handleCloseUserModal }
          >Maybe letter!</Button>
          <Button 
            variant="danger" 
            onClick={ () =>handleDeleteUserData(userId) }
          >Yes, Delete it!</Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}

export default Users;