import React, { useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import { InputField, InputSelect } from '../common';
import CardIcons from './CardIcons';
import SecurityMore from './SecurityMore';
import { handleNumbersOnlyKeyDown, expiryMonthOptions, expiryYearOptions, ccFormat, ccType, removeWhiteSpace } from '../../utils';
import useForm from '../form/useForm';
import validate from '../form/CcValidation';
import { usePayment } from '../../context/payment';
import useCommit from '../../utils/useCommit';

const CardMethod = () => {
  const { acceptTerms, setAcceptTerms, setCardData, checkoutHandler } = usePayment();
  const { setIsCommitting, FormSubmit } = useCommit();

  const makePayment = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      if (acceptTerms.isActive) {
        checkoutHandler();
        setIsCommitting(true);
      } else {
        setAcceptTerms({
          isActive: false,
          error: true
        })
      }
    }
  }

  const { 
    values, 
    errors, 
    handleChange, 
    handleSelectChange,
    handleSubmit 
  } = useForm(makePayment, validate);

  useEffect(() => {
    let cardNumber;
    let expiryDate;
    if (values && values.cardNumber !== undefined) {
      cardNumber = (values.cardNumber).replace(/\s/g,'');
    }
    if (values && values.expiryMonth !== undefined && values.expiryYear !== undefined) {
      expiryDate = values.expiryMonth + '/' + values.expiryYear;
    }

    setCardData({
      number: cardNumber,
      name: values.cardholderName,
      expiry: expiryDate,
      security: values.securityCode
    });
    
    // eslint-disable-next-line
  }, [values]);

  return (
    <div className="CardMethod">
      <Form onSubmit={handleSubmit} noValidate>
        <CardIcons />
        <div className="position-relative">
          <div className="CardMethod-FieldIcon">
            <span className={`CardMethod-FieldIcon__${ccType(removeWhiteSpace(values.cardNumber))}`}></span>
          </div>
          <InputField
            type="tel"
            name="cardNumber"
            label="Card number"
            maxLength="19"
            value={ccFormat(values.cardNumber) || ''}
            onChange={handleChange}
            onKeyDown={handleNumbersOnlyKeyDown}
            errorMsg={errors.cardNumber}
            required
            autoFocus
          />
        </div>
        <InputField
          type="text"
          name="cardholderName"
          label="Cardholder name"
          value={values.cardholderName || ''}
          onChange={handleChange}
          errorMsg={errors.cardholderName}
          required
        />
        <Row>
          <Col md="7">
            <Row>
              <Col xs="6" className="pr-1">
                <InputSelect
                  name="expiryMonth"
                  label="Expiry on"
                  placeholder="Month"
                  options={expiryMonthOptions}
                  onChange={(event) => handleSelectChange(event.value, 'expiryMonth')}
                  errorMsg={errors.expiryMonth}
                  required={true}
                />
              </Col>
              <Col xs="6" className="pl-1">
                <InputSelect
                  name="expiryYear"
                  label="&nbsp;"
                  placeholder="Year"
                  options={expiryYearOptions()}
                  onChange={(event) => handleSelectChange(event.value, 'expiryYear')}
                  errorMsg={errors.expiryYear}
                  required={true}
                />
              </Col>
            </Row>
          </Col>
          <Col md="5">
            <div className="position-relative">
              <SecurityMore />
              <InputField
                type="tel"
                name="securityCode"
                label="Security code"
                maxLength="4"
                value={values.securityCode || ''}
                onChange={handleChange}
                onKeyDown={handleNumbersOnlyKeyDown}
                errorMsg={errors.securityCode}
                required
              />
            </div>
          </Col>
        </Row>
        <FormSubmit
          block
          size="lg"
          type="submit"
          variant="primary"
          btnText="Make Payment"
        />
      </Form>
    </div>
  );
}
 
export default CardMethod;