import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Dropdown, Form } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Layout from '../components/Layout';
import useCommit from '../utils/useCommit';
import TablePagination from '../utils/TablePagination';

const Invoices = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [contact, setContact] = useState({
    keywords: ''
  });
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState({
    type: 'list',
    currentPage: 1,
    totalPages: 1
  });

  const fileDownload = (id) => {
    const baseUrl = process.env.REACT_APP_API_URI;
    const url = baseUrl + '/api/customers/invoices/download/' + id;
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
  }
  const { setIsCommitting, FormSubmit } = useCommit();

  const fetchData = (pageNumber) => {
    axios.get('/api/customers/invoices', {
        params: { page: pageNumber }
      })
      .then(res => {
        setInvoices(res.data.data);
        setIsLoaded(true);
        setContact({
          keywords: ''
        });
        setPage({
          type: 'list',
          currentPage: res.data.current_page,
          totalPages: res.data.last_page,
          total: res.data.total,
          per_page: res.data.per_page,
        });
      })
      .catch(error => console.log(error));
  }

  const fetchSearchData = (pageNumber) => {
    const { keywords } = contact;
    axios.post('/api/customers/invoices/search', {
        keywords
      }, {
        params: { page: pageNumber }
      })
      .then(res => {
        setInvoices(res.data.data);
        setIsCommitting(false);
        setPage({
          type: 'search',
          currentPage: res.data.current_page,
          totalPages: res.data.last_page,
          total: res.data.total,
          per_page: res.data.per_page,
        });

      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  const handleFilter = (event) => {
    event.preventDefault();
    setIsCommitting(true);
    fetchSearchData(1);
  }

  const handlePagination = (pageNumber) => {
    if (page.type === 'list') {
      fetchData(pageNumber);
    } else {
      fetchSearchData(pageNumber);
    }
  }


  return (
    <Layout dataIsLoaded={isLoaded}>
      <div className="Dashboard-ContentBody__Header">
        <Row>
          <Col md="6">
            <h1 className="Dashboard-ContentBody__Header--Title">
              Invoices
            </h1>
          </Col>
        </Row>
      </div>
      <div className="Dashboard-ContentBody__TableData">
        <Row>
          <Col>
            <Card className="shadow-sm border-0">
              <Card.Header className="bg-white pt-4">
                <Form onSubmit={handleFilter}>
                  <Row>
                    <Col md="9">
                      <Form.Group controlId="formSearch">
                        <Form.Label className="sr-only">Search</Form.Label>
                        <Form.Control 
                          type="text" 
                          placeholder="Search for a invoice" 
                          value={contact.keywords}
                          onChange={(event) => setContact({ ...contact, keywords: event.target.value })}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <FormSubmit
                        type="submit"
                        variant="secondary"
                        btnText="Search"
                      />
                    </Col>
                  </Row>
                </Form>
              </Card.Header>
              <Card.Body>
                <Table responsive>
                  <colgroup>
                    <col width="auto" />
                    <col width="28%" />
                    <col width="14%" />
                    <col width="14%" />
                    <col width="50px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Invoice</th>
                      <th>Technician</th>
                      <th>Referance</th>
                      <th className="totalCol">Price</th>
                      <th className="tableNav">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      invoices.map(invoice => (
                        <tr key={invoice.id}>
                          <td>
                            <strong>#{invoice.invoice_id}</strong>
                            <small className="text-secondary">
                              <Moment date={invoice.created_at} format="ddd, DD MMM YYYY" />
                            </small>
                          </td>
                          <td>
                            {
                              invoice.technician &&
                              <strong>{invoice.technician.name}</strong>
                            }
                          </td>
                          <td>
                            #{invoice.quotation_id}
                          </td>
                          <td className="totalCol">
                            ${ parseFloat(invoice.total).toFixed(2) }
                          </td>
                          <td className="tableNav">
                            <Dropdown>
                              <Dropdown.Toggle id="table-more">
                                <MoreVertical size="18" />
                              </Dropdown.Toggle>
    
                              <Dropdown.Menu className="shadow border-0" alignRight>
                                  <Link 
                                    to={`/invoice/${invoice.id}`}
                                    className="dropdown-item"
                                  >View</Link>
                                  {
                                    invoice.remain_amount > 0 ? '' :
                                    <Link 
                                    to="#"
                                    onClick={() => fileDownload(invoice.id)}
                                    className="dropdown-item"
                                    >Download</Link>
                                  }
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                { 
                  page.total > page.per_page &&
                  <TablePagination 
                    currentPage={parseInt(page.currentPage)}
                    totalPages={parseInt(page.totalPages)}
                    clickHandler={handlePagination}
                  />
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default Invoices;