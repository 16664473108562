export default function validate(values, required) {
  let errors = {};

  if (required.bankName && !values.bankName) {
    errors.bankName = 'Bank name is required' ;
  }

  if (required.name && !values.name) {
    errors.name = 'A/C name is required' ;
  }

  if (required.number && !values.number) {
    errors.number = 'A/C number is required';
  }

  if (required.bsb && !values.bsb) {
    errors.bsb = 'BSB is required';
  }

  return errors;
}