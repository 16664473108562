import React from 'react';
import { Table } from 'react-bootstrap';

const Invoice = (props) => {
  const { data } = props
  return (
    <center>
      <Table cellPadding="0" cellSpacing="0" border="0" width="100%" bgcolor="#FFFFFF">
        <tbody>
          <tr>
            <td width="100%" style={{ border: 0 }}>
              <table cellPadding="0" cellSpacing="0" border="0" width="100%">
                <tbody>
                  <tr>
                    <td style={{ border: 0 }}>
                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" style={{ width: '100%' }} align="center">
                        <tbody>
                          <tr>
                            <td style={{ border: 0, padding: 0, color: '#000', textAlign: 'left' }} width="100%" bgcolor="#FFFFFF" align="left">
                              
                              {/* Header */}
                              <table cellPadding="0" cellSpacing="0" border="0" width="100%" style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                  <tr>
                                    <td style={{ border: 0, padding: '30px 15px 30px 30px' }} valign="top" align="left">
                                      <div>
                                        <a href="!#" style={{ display: 'inline-block', textDecoration: 'none' }}>
                                          <span style={{ textDecoration: 'none' }}>
                                            <img width="125" height="auto" src="https://www.geeks.sydney/frontend/images/logo.png" border="0" />
                                          </span>
                                        </a>
                                      </div>
                                    </td>
                                    <td style={{ border: 0, padding: '30px 15px 30px 30px' }} valign="top" align="right">
                                      <div>
                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                          <span style={{ fontSize: '14px', color: '#312f32' }}>Need help?</span>
                                        </span>
                                      </div>
                                      <div>
                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                          <span style={{ fontSize: '24px', fontWeight: 'bold', color: '#f97807' }}>1300 912 176</span>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            
                              {/* Invoice Details */}
                              <table cellPadding="0" cellSpacing="0" border="0" width="100%" style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                  <tr>
                                    <td style={{ border: 0, padding: '30px' }} valign="top" align="left">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" style={{ tableLayout: 'fixed' }}>
                                        <tbody>
                                          <tr>
                                            <td style={{ border: 0, padding: 0 }} valign="top" align="left">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '18px', color: '#312f32' }}>Customer Details</span>
                                                </span>
                                              </div>
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#312f32' }}>{ data.customer.full_name}</span>
                                                </span>
                                              </div>
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', color: '#312f32' }}>{ data.customer.email}</span>
                                                </span>
                                              </div>
                                              <div style={{ marginBottom: '20px' }}>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', color: '#312f32' }}>
                                                    { data.customer.street}<br /> 
                                                    { data.customer.city +', ' +data.customer.state+' '+data.customer.post_code }
                                                  </span>
                                                </span>
                                              </div>
                                            </td>
                                            <td style={{ border: 0, padding: 0 }} valign="top" align="left">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '32px', color: '#312f32' }}>INVOICE</span>
                                                </span>
                                              </div>
                                              <table cellPadding="0" cellSpacing="0" border="0" width="100%" style={{ tableLayout: 'fixed' }}>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ border: 0, padding: 0 }} width="100" valign="top" align="left">
                                                      <div>
                                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                          <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#312f32' }}>Invoice no</span>
                                                        </span>
                                                      </div>
                                                    </td>
                                                    <td style={{ border: 0, padding: 0 }} valign="top" align="left">
                                                      <div>
                                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                          <span style={{ fontSize: '14px', color: '#312f32' }}>: #{ data.invoice_id }</span>
                                                        </span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td style={{ border: 0, padding: 0 }} width="100" valign="top" align="left">
                                                      <div>
                                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                          <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#312f32' }}>Date</span>
                                                        </span>
                                                      </div>
                                                    </td>
                                                    <td style={{ border: 0, padding: 0 }} valign="top" align="left">
                                                      <div>
                                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                          <span style={{ fontSize: '14px', color: '#312f32' }}>: 28 May, 2020 at 02:00 PM</span>
                                                        </span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td style={{ border: 0, padding: 0 }} width="100" valign="top" align="left">
                                                      <div>
                                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                          <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#312f32' }}>Ref.</span>
                                                        </span>
                                                      </div>
                                                    </td>
                                                    <td style={{ border: 0, padding: 0 }} valign="top" align="left">
                                                      <div>
                                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                          <span style={{ fontSize: '14px', color: '#312f32' }}>: #{ data.quotation_id }</span>
                                                        </span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td style={{ border: 0, padding: 0 }} width="100" valign="top" align="left">
                                                      <div>
                                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                          <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#312f32' }}>Served by</span>
                                                        </span>
                                                      </div>
                                                    </td>
                                                    <td style={{ border: 0, padding: 0 }} valign="top" align="left">
                                                      <div>
                                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                          <span style={{ fontSize: '14px', color: '#312f32' }}>: { data.technician.name }</span>
                                                        </span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td style={{ border: 0, padding: 0 }} width="100" valign="top" align="left">
                                                      <div>
                                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                          <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#312f32' }}>Total</span>
                                                        </span>
                                                      </div>
                                                    </td>
                                                    <td style={{ border: 0, padding: 0 }} valign="top" align="left">
                                                      <div>
                                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                          <span style={{ fontSize: '14px', color: '#312f32' }}>: ${ parseFloat(data.total).toFixed(2) }</span>
                                                        </span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            
                              {/* Invoice Item */}
                              <table cellPadding="0" cellSpacing="0" border="0" width="100%" style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                  <tr>
                                    <td style={{ border: 0, padding: '0 0 30px 0' }} valign="top" align="left">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" style={{ tableLayout: 'fixed' }}>
                                        <thead className="tableDataHeader">
                                          <tr>
                                            <th width="40%" style={{ border: 0, padding: '10px 30px' }} valign="top" bgcolor="#f97807" align="left">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#ffffff' }}>ITEM</span>
                                                </span>
                                              </div>
                                            </th>
                                            <th width="20%" style={{ border: 0, padding: '10px 30px', textAlign: 'left' }} valign="top" bgcolor="#312f32" align="right">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#ffffff' }}>UNIT PRICE</span>
                                                </span>
                                              </div>
                                            </th>
                                            <th width="20%" style={{ border: 0, padding: '10px 30px', textAlign: 'left' }} valign="top" bgcolor="#312f32" align="right">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#ffffff' }}>QTY./HOURS</span>
                                                </span>
                                              </div>
                                            </th>
                                            <th width="20%" style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="top" bgcolor="#312f32" align="right">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#ffffff' }}>PRICE</span>
                                                </span>
                                              </div>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody className="tableDataBody">
                                          {
                                            (data.order_details).map(item => (
                                              <tr key={ item.id }>
                                                <td style={{ borderBottom: '1px solid #eee', padding: '10px 30px' }} valign="center" align="left">
                                                  <div>
                                                    <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#312f32' }}>{ item.name }</span>
                                                    </span>
                                                  </div>
                                                </td>
                                                <td style={{ borderBottom: '1px solid #eee', padding: '10px 30px' }} valign="center" align="left">
                                                  <div>
                                                    <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                      <span style={{ fontSize: '14px', color: '#312f32' }}>${ parseFloat(item.price).toFixed(2) }</span>
                                                    </span>
                                                  </div>
                                                </td>
                                                <td style={{ borderBottom: '1px solid #eee', padding: '10px 30px' }} valign="center" align="left">
                                                  <div>
                                                    <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', color: '#312f32' }}>{item.quantity}</span>
                                                    </span>
                                                  </div>
                                                </td>
                                                <td style={{ borderBottom: '1px solid #eee', padding: '10px 30px' }} valign="center" align="right">
                                                  <div>
                                                    <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                      <span style={{ fontSize: '14px', color: '#312f32' }}>${( parseFloat(item.price)*(item.quantity)).toFixed(2) }</span>
                                                    </span>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))
                                          }
                                        </tbody>
                                        <tfoot className="tableDataFooter">
                                          {
                                            data.discount_amount > 0 &&
                                          <tr>
                                            <th style={{ border: 0, padding: '10px 30px' }} valign="center" align="right">&nbsp;</th>
                                            <th colSpan="2" style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', color: '#312f32' }}>Discount (-)</span>
                                                </span>
                                              </div>
                                            </th>
                                            <th style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                <span style={{ fontSize: '14px', color: '#312f32' }}>${ parseFloat(data.discount_amount).toFixed(2) }</span>
                                                </span>
                                              </div>
                                            </th>
                                          </tr>
                                          }
                                          {
                                            data.surcharge_amount > 0 &&
                                            <tr>
                                              <th style={{ border: 0, padding: '10px 30px' }} valign="center" align="right">&nbsp;</th>
                                              <th colSpan="2" style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                                <div>
                                                  <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', color: '#312f32' }}>Surcharge (+)</span>
                                                  </span>
                                                </div>
                                              </th>
                                              <th style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                                <div>
                                                  <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', color: '#312f32' }}>${ parseFloat(data.surcharge_amount).toFixed(2) }</span>
                                                  </span>
                                                </div>
                                              </th>
                                            </tr>
                                          }
                                          {
                                            data.sdbf_charge > 0 &&
                                            <tr>
                                              <th style={{ border: 0, padding: '10px 30px' }} valign="center" align="right">&nbsp;</th>
                                              <th colSpan="2" style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                                <div>
                                                  <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', color: '#312f32' }}>Sameday Fee (+)</span>
                                                  </span>
                                                </div>
                                              </th>
                                              <th style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                                <div>
                                                  <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', color: '#312f32' }}>${ parseFloat(data.sdbf_charge).toFixed(2) }</span>
                                                  </span>
                                                </div>
                                              </th>
                                            </tr>
                                          }
                                          {
                                            data.penalty > 0 &&
                                            <tr>
                                              <th style={{ border: 0, padding: '10px 30px' }} valign="center" align="right">&nbsp;</th>
                                              <th colSpan="2" style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                                <div>
                                                  <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', color: '#312f32' }}>Penalty (+)</span>
                                                  </span>
                                                </div>
                                              </th>
                                              <th style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                                <div>
                                                  <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', color: '#312f32' }}>${ parseFloat(data.penalty).toFixed(2) }</span>
                                                  </span>
                                                </div>
                                              </th>
                                            </tr>
                                          }
                                          <tr>
                                            <th style={{ border: 0, padding: '10px 30px' }} valign="center" align="right">&nbsp;</th>
                                            <th colSpan="2" style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', color: '#312f32' }}>Subtotal</span>
                                                </span>
                                              </div>
                                            </th>
                                            <th style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', color: '#312f32' }}>${ parseFloat(data.subtotal).toFixed(2) }</span>
                                                </span>
                                              </div>
                                            </th>
                                          </tr>
                                          <tr>
                                            <th style={{ border: 0, padding: '10px 30px' }} valign="center" align="right">&nbsp;</th>
                                            <th colSpan="2" style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', color: '#312f32' }}>GST (+)</span>
                                                </span>
                                              </div>
                                            </th>
                                            <th style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', color: '#312f32' }}>${ parseFloat(data.gst).toFixed(2) }</span>
                                                </span>
                                              </div>
                                            </th>
                                          </tr>
                                          {
                                            data.amex_charge > 0 &&
                                            <tr>
                                              <th style={{ border: 0, padding: '10px 30px' }} valign="center" align="right">&nbsp;</th>
                                              <th colSpan="2" style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                                <div>
                                                  <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', color: '#312f32' }}>Amex Charge (+)</span>
                                                  </span>
                                                </div>
                                              </th>
                                              <th style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                                <div>
                                                  <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', color: '#312f32' }}>${ parseFloat(data.amex_charge).toFixed(2) }</span>
                                                  </span>
                                                </div>
                                              </th>
                                            </tr>
                                          }
                                          <tr>
                                            <th style={{ border: 0, padding: '10px 30px' }} valign="center" align="right">&nbsp;</th>
                                            <th colSpan="2" style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', color: '#312f32' }}>Total Amount</span>
                                                </span>
                                              </div>
                                            </th>
                                            <th style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', color: '#312f32' }}>${ parseFloat(data.total).toFixed(2) }</span>
                                                </span>
                                              </div>
                                            </th>
                                          </tr>
                                          {
                                            data.paid_amount > 0 &&
                                            <tr>
                                              <th style={{ border: 0, padding: '10px 30px' }} valign="center" align="right">&nbsp;</th>
                                              <th colSpan="2" style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                                <div>
                                                  <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', color: '#312f32' }}>Paid Amount</span>
                                                  </span>
                                                </div>
                                              </th>
                                              <th style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                                <div>
                                                  <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', color: '#312f32' }}>${ parseFloat(data.paid_amount).toFixed(2) }</span>
                                                  </span>
                                                </div>
                                              </th>
                                            </tr>
                                          }
                                          {
                                            data.remain_amount > 0 &&
                                            <tr>
                                              <th style={{ border: 0, padding: '10px 30px' }} valign="center" align="right">&nbsp;</th>
                                              <th colSpan="2" style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                                <div>
                                                  <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', color: '#312f32' }}>Remaining</span>
                                                  </span>
                                                </div>
                                              </th>
                                              <th style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" align="right">
                                                <div>
                                                  <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                    <span style={{ fontSize: '14px', color: '#312f32' }}>${ parseFloat(data.remain_amount).toFixed(2) }</span>
                                                  </span>
                                                </div>
                                              </th>
                                            </tr>
                                          }
                                          <tr>
                                            <th style={{ border: 0, padding: '10px 30px' }} valign="center" align="right">&nbsp;</th>
                                            <th colSpan="2" style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" bgcolor="#f97807" align="right">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '12px', color: '#ffffff' }}>GRAND TOTAL</span>
                                                </span>
                                              </div>
                                            </th>
                                            <th style={{ border: 0, padding: '10px 30px', textAlign: 'right' }} valign="center" bgcolor="#f97807" align="right">
                                              <div>
                                                <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                                  <span style={{ fontSize: '14px', color: '#ffffff' }}>${ parseFloat(data.total).toFixed(2) }</span>
                                                </span>
                                              </div>
                                            </th>
                                          </tr>
                                        </tfoot>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            
                              {/* Invoice Footer */}
                              <table cellPadding="0" cellSpacing="0" border="0" width="100%" style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                  <tr>
                                    <td style={{ border: 0, padding: '30px' }} valign="top" align="left">
                                      <div>
                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                          <span style={{ fontSize: '14px', color: '#6c757d' }}>
                                            <strong>Job Description:</strong><br />
                                            040 -346- 5644 alternate number of her sister Marcel. Having trouble connecting computer to the internet
                                          </span>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ border: 0, padding: '30px' }} valign="top" align="left">
                                      <div>
                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                          <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#312f32' }}>Thank you for your business!</span>
                                        </span>
                                      </div>
                                      <div>
                                        <span style={{ fontFamily: 'arial,helvetica,sans-serif' }}>
                                          <span style={{ fontSize: '12px', color: '#6c757d' }}>&copy; 2020 Geeks Sydney Pty LTD. ABN: 59616635664</span>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </Table>
    </center>
  );
}
 
export default Invoice;