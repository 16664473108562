import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import axios from 'axios';

import useCommit from '../utils/useCommit';

const ForgetPassword = (props) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { setIsCommitting, FormSubmit, setMessage, AlertMessage } = useCommit();


  const handleChangeEmail = (event) => {
    const value = event.target.value;

    if (value) {
      setEmail(value);
      setError('');
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email) {
      setIsCommitting(true);
      axios.post('/api/customers/forget-password',{
        email: email
      }).then(res =>{
        setIsCommitting(false);
        if (res.data.status === 'success')
        {
          setMessage({
            type: 'success',
            text: res.data.message
          });
        }else
        {
          setMessage({
            type: 'danger',
            text: res.data.message
          });
        }
      }).catch(error => {

      });
    } else {
      setError('Email address is required!');
    }
  }

  return (
    <Modal
      {...props}
      size="sm"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Forget Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertMessage />
          <Form onSubmit={handleSubmit} noValidate>
            <Form.Group controlId="emailAddress">
              <Form.Label>Email address</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Enter email" 
                value={email}
                onChange={handleChangeEmail}
                isInvalid={!!error}
              />
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                Enter your account's email address and we will send you a password reset link.
              </Form.Text>
            </Form.Group>
            <FormSubmit
              block
              type="submit"
              variant="primary"
              btnText="Send password reset email"
            />
          </Form>
      </Modal.Body>
    </Modal>
  );
}
 
export default ForgetPassword;