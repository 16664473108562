import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Modal, Button } from 'react-bootstrap';
import axios from 'axios';

import Layout from '../components/Layout';
import useCommit from '../utils/useCommit';
import TablePagination from '../utils/TablePagination';


const BillingSummary = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { scrollToTop, setMessage, AlertMessage } = useCommit();

  const [widtdrawal, setWidtdrawal] = useState(0);
  const [perdingInvoice, setPerdingInvoice] = useState([]);
  const [paidInvoice, setPaidInvoice] = useState([]);

  const [showPayment, setShowPayment] = useState(false);

  const handleClosePayment = () => setShowPayment(false);
  const handleShowPayment = () => setShowPayment(true);

  const [page, setPage] = useState({
    currentPage: 1,
    totalPages: 1
  });

  const handleWithdrawalRequest = () => {
    axios.post('/api/partners/withdrawal-request').then(res => {
      setShowPayment(false);
      setMessage({
        type: 'success',
        text: res.data.message
      });
      scrollToTop();
      dataList();

    }).catch(error => {
      console.log(error);
    });
  }

  useEffect(() => {
    setIsLoaded(false);
    dataList();
    fetchData(1);

  }, []);

  const dataList = () => {
    axios.get('/api/partners/income-summary')
      .then(res => {
        setWidtdrawal(res.data.withdrawal);
        setPerdingInvoice(res.data.pendinginvoices);
      }).catch(error => {
        console.log(error)
      })
  }
    const fetchData = (pageNumber) => {
    axios.get('/api/partners/income-summary/paid?page=' + pageNumber)
      .then(res => {
        setPaidInvoice(res.data.data);
        setPage({
          currentPage: res.data.current_page,
          totalPages: res.data.last_page
        });
        setIsLoaded(true);
      })
      .catch(error => console.log(error));
  }
  const fileDownload = (id) => {
      const baseUrl = process.env.REACT_APP_API_URI;
      const url = baseUrl +'/api/partners/transaction/download/'+ id;
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
  }

  const handlePagination = (pageNumber) => {
      fetchData(pageNumber);
  }

  return (
    <Layout dataIsLoaded={isLoaded}>
      <div className="Dashboard-ContentBody__Header">
        <h1 className="Dashboard-ContentBody__Header--Title">
          Income Summary
        </h1>
        < AlertMessage / >
      </div>
      <div className="Dashboard-ContentBody__Form">
        <Row>
          <Col md="12">
            <Card className="shadow-sm mb-4">
              <Card.Body>
                <Row>
                  <Col md="8">
                    <h5 className="mb-3">Your net income</h5>
                    <Button
                      variant="success"
                      onClick={handleShowPayment}
                    >Withdraw</Button>
                    <Modal 
                      centered
                      size="sm"
                      show={showPayment} 
                      dialogClassName="Dashboard-ContentBody__RequestPayment"
                      onHide={handleClosePayment}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Request Payment</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="text-center">
                        <p className="text-secondary font-weight-bold mt-3 mb-0">Total You Receive</p>
                        <h1 className="text-success">${ widtdrawal }</h1>
                        <p className="text-secondary small">Please note, your payment will be send to the bank.</p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button 
                          block
                          variant="primary" 
                          onClick={ handleWithdrawalRequest }
                          disabled={ widtdrawal > 0 ? false : true }
                        >Complete Request</Button>
                      </Modal.Footer>
                    </Modal>
                  </Col>
                  <Col md="4">
                    <div className="text-md-right">
                      <h3>${ widtdrawal }</h3>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="shadow-sm">
              <Card.Body>
                <h5 className="mb-3">Pending Request</h5>
                <Table responsive>
                  <colgroup>
                    <col width="30%" />
                    <col width="45%" />
                    <col width="25%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Payment Id</th>
                      <th>Request Date</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      perdingInvoice.map(item => (
                        <tr className="table-danger" key="item.id">
                          <td>#{ item.payment_id }</td>
                          <td>{ item.created_at }</td>
                          <td>${ parseFloat(item.total).toFixed(2) }</td>
                        </tr>
                      ))
                    }
                    
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="shadow-sm">
              <Card.Body>
                <h5 className="mb-3">Transactions</h5>
                <Table responsive>
                  <colgroup>
                    <col width="50%" />
                    <col width="25%" />
                    <col width="25%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Payment Id</th>
                      <th>Total</th>
                      <th>Invoice</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      paidInvoice.map(item => (
                        <tr key="item.id">
                          <td>
                            #{ item.payment_id }<br/>
                            <small>{ item.created_at }</small>
                          </td>
                          <td>${ parseFloat(item.total).toFixed(2) }</td>
                          <td><button type="button" className="btn btn-sm btn-primary" onClick={()=>fileDownload(item.id) }>Download</button></td>
                        </tr>
                      ))
                    }
                    
                  </tbody>
                </Table>
                <TablePagination 
                  currentPage={parseInt(page.currentPage)}
                  totalPages={parseInt(page.totalPages)}
                  clickHandler={handlePagination}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default BillingSummary;