export default function validate(values, required) {
  let errors = {};

  if (required.address1 && !values.address1) {
    errors.address1 = 'Street address is required';
  }

  if (required.city && !values.city) {
    errors.city = 'City is required';
  }

  if (required.state && !values.state) {
    errors.state = 'Choose a state';
  }

  if (required.zipcode && !values.zipcode) {
    errors.zipcode = 'Zipcode is required';
  }

  return errors;
}