export default function validate(values, required) {
  let errors = {};

  if (required.email) {
    if (!values.email) {
      errors.email = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is not valid';
    }
  }
  
  if (required.password && !values.password) {
    errors.password = 'Password is required';
  }

  return errors;
}