import React from 'react';
import { Image } from 'react-bootstrap';

import notFound from '../assets/images/not-found.png';

const NotFound = () => {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      <Image fluid src={notFound} alt="Page not found" />
    </div>
  );
}
 
export default NotFound;