import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Table, Badge, Card, Dropdown } from 'react-bootstrap';
import { DollarSign, Disc, Share2, Award, MoreVertical } from 'react-feather';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import axios from 'axios';

import Layout from '../components/Layout';
import DashboardCard from '../components/DashboardCard';
import AppointmentView from '../components/AppointmentView';
import bannerAds from '../assets/images/dashboard-ads.png';

const Dashboard = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [dataDashboard, setDataDashboard] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [dataData, setDataData] = useState([]);
  const [viewModal, setViewModal] = useState({
    isActive: false,
    appId: null
  });
  useEffect(() => {
    setIsLoaded(false);
    axios.get('/api/customers/dashboard').then(res => {
      setDataData(res.data);
      setAppointments(res.data.appointments);
      setIsLoaded(true);
    }).catch();

  }, []);

  function statusColor($status) {
    if ($status === 'open')
      return "primary";
    else if($status === 'rescheduled')
      return "secondary";
    else if($status === 'cancelled')
      return "danger";
    else if ($status === 'reassigned')
      return "info";
    else if ($status === 'hold')
      return "warning";
    else if ($status === 'dispute')
      return "info";
    else if ($status === 'delivered')
      return "success";
    else if ($status === 'reopen')
      return "dark";
    else
      return "warning";
    
  }
  return (
    <Layout dataIsLoaded={isLoaded}>
      <div className="Dashboard-ContentBody__Header">
        <h1 className="Dashboard-ContentBody__Header--Title">
          Dashboard
        </h1>
      </div>
      <div className="Dashboard-ContentBody__CardData">
        <Row>
          {/* <Col xs={12}>
            <div className="w-100 mb-4">
              <Image fluid src={bannerAds} />
            </div>
          </Col> */}
          <DashboardCard 
            title="Appointments" 
            count={dataData.totalAppointment } 
            comData={ dataDashboard.monthlyBookingIncDec }
            icon={<Disc />}
          />
          {/* <DashboardCard 
            title="Coin" 
            count="77" 
            comData={ dataDashboard.bookingIncDec }
            icon={<Disc />}
          /> */}
          <DashboardCard 
            title="Refer" 
            count={ dataData.refer }   
            comData={ dataDashboard.pendingBookingIncDec }
            icon={<Share2 />}
          />
          <DashboardCard 
            title="Rewards" 
            count={ dataData.rewards } 
            comData={ dataDashboard.cancelledBookingIncDec }
            icon={<Award />}
          />
          <Col xs={12}>
            <Card className="Dashboard-ContentBody__TableData shadow-sm border-0 mt-3">
              <Card.Body>
                <Table responsive>
                  <colgroup>
                    <col width="auto" />
                    <col width="28%" />
                    <col width="14%" />
                    <col width="14%" />
                    <col width="50px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Appointment</th>
                      <th>Appointment Date</th>
                      <th>Status</th>
                      <th className="totalCol">Total</th>
                      <th className="tableNav">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                   {
                      appointments.map(appointment => (
                        <tr key={appointment.id}>
                          <td>
                            <strong>{appointment.serv_name}</strong>
                            <small className="text-secondary">
                              #{appointment.booking_id} | <Moment date={appointment.created_at} format="ddd, DD MMM YYYY" />
                            </small>
                          </td>
                          <td>
                            <Moment date={appointment.app_date} format="ddd, DD MMM YYYY" />, <Moment date={appointment.app_date+' '+appointment.app_time} format="hh:mm A" />
                          </td>
                          <td>
                            <Badge
                              variant={ statusColor(appointment.app_status) }
                            ><span className="text-capitalize font-weight-normal">{appointment.app_status}</span></Badge>
                          </td>
                          <td className="totalCol">
                            ${appointment.serv_cost}
                            <small className={`text-capitalize text-secondary ${appointment.payment_status === 'paid' ? 'text-success' : 'text-danger'}`}>{appointment.payment_status}</small>
                          </td>
                          <td className="tableNav">
                            <Dropdown>
                              <Dropdown.Toggle id="table-more">
                                <MoreVertical size="18" />
                              </Dropdown.Toggle>
    
                              <Dropdown.Menu className="shadow border-0" alignRight>
                                  <Link 
                                    to="#"
                                    className="dropdown-item"
                                    onClick={() => setViewModal({ isActive: true, appId: appointment.id })}
                                  >View</Link>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                
                <AppointmentView
                  data={appointments.find(appointment => appointment.id === viewModal.appId)}
                  show={viewModal.isActive}
                  onHide={() => setViewModal({ isActive: false, appId: null })}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default Dashboard;