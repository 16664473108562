import React, { useState } from 'react';
import { Button, Alert } from 'react-bootstrap';

const useCommit = () => {
  const [isCommitting, setIsCommitting] = useState(false);
  const [message, setMessage] = useState({
    type: '',
    text: ''
  });
  const [messageModal, setModalMessage] = useState({
    type: '',
    text: ''
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  const FormSubmit = (props) => {
    const { btnText, ...rest } = props;

    return (
      <Button
        {...rest}
        disabled={isCommitting}
      >{ isCommitting ? 'Please wait...' : btnText }</Button>
    );
  }

  const AlertMessage = () => {
    return (
      <Alert 
        dismissible
        variant={message.type} 
        show={message.type !== '' && message.text !== ''}
        onClose={() => setMessage({ type: '', text: '' })} 
      >{message.text}</Alert>
    );
  }

  const AlertModalMessage = () => {
    return (
      <Alert 
        dismissible
        variant={messageModal.type} 
        show={messageModal.type !== '' && messageModal.text !== ''}
        onClose={() => setModalMessage({ type: '', text: '' })} 
      >{messageModal.text}</Alert>
    );
  }

  return {
    scrollToTop,
    setIsCommitting,
    FormSubmit,
    setMessage,
    setModalMessage,
    AlertMessage,
    AlertModalMessage
  };
}
 
export default useCommit;