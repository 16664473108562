import React, { Fragment } from 'react';
import { Row, Col, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { InputField, InputSelect } from './common';
import { handleNumbersOnlyKeyDown } from '../utils';
import { stateOptions } from '../staticData';
import useForm from './form/useForm';
import validate from './form/JoinValidation';
import useCommit from '../utils/useCommit';
import axios from 'axios';

const PopupJoin = (props) => {
  const { setIsCommitting, FormSubmit, setMessage, AlertMessage } = useCommit();

  const userJoin = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      setIsCommitting(true);
      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        password: values.password,
        street: values.address,
        city: values.city,
        state: values.state,
        post_code: values.zipcode,
      };
      axios.post('/api/customers/join', data).then(res => {
        if (res.data.status === '204') {
          setMessage({
            type: 'danger',
            text: res.data.message
          });
          setIsCommitting(false);
        }else{
          setMessage({
            type: 'success',
            text: res.data.message
          });
          setIsCommitting(false);
        }
      })
      .catch(error => {
        console.log(error);  
      });
    }
  }

  const terms = () => {
    const link = document.createElement('a');
    link.href = 'https://geeks.sydney/terms-and-conditions';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  }

  const { 
    values, 
    errors, 
    handleChange, 
    handleSelectChange,
    handleSubmit 
  } = useForm(userJoin, validate);

  return (
    <Modal
      {...props}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create a New Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertMessage />
        <Form onSubmit={handleSubmit} noValidate>
          <Row>
            <Col md="6">
              <InputField
                type="text"
                name="first_name"
                label="First Name"
                value={values.first_name || ''}
                onChange={handleChange}
                errorMsg={errors.first_name}
                required
              />
            </Col>
            <Col md="6">
              <InputField
                type="text"
                name="last_name"
                label="Last Name"
                value={values.last_name || ''}
                onChange={handleChange}
                errorMsg={errors.last_name}
                required
              />
            </Col>
            <Col md="6">
              <InputField
                type="email"
                name="email"
                label="Email address"
                value={values.email || ''}
                onChange={handleChange}
                errorMsg={errors.email}
                required
              />
            </Col>
            <Col md="6">
              <InputField
                type="text"
                name="phone"
                label="Phone number"
                maxLength={10}
                value={values.phone || ''}
                onChange={handleChange}
                onKeyDown={handleNumbersOnlyKeyDown}
                errorMsg={errors.phone}
                required
              />
            </Col>
            <Col md="12">
              <InputField
                type="password"
                name="password"
                label="Password"
                value={values.password || ''}
                onChange={handleChange}
                errorMsg={errors.password}
                required
              />
            </Col>
            <Col md="6">
              <InputField
                type="text"
                name="address"
                label="Street"
                placeholder="Street address, P.O. Box"
                value={values.address || ''}
                onChange={handleChange}
                errorMsg={errors.address}
                required
              />
            </Col>
            <Col md="6">
              <InputField
                type="text"
                name="city"
                label="City"
                value={values.city || ''}
                onChange={handleChange}
                errorMsg={errors.city}
                required
              />
            </Col>
            <Col md="6">
              <InputSelect
                name="state"
                label="State"
                placeholder="State"
                options={stateOptions}
                value={stateOptions.find(item => item.value === values.state)}
                onChange={(event) => handleSelectChange(event.value, 'state')}
                errorMsg={errors.state}
                required={true}
              />
            </Col>
            <Col md="6">
              <InputField
                type="text"
                name="zipcode"
                label="Zip code"
                value={values.zipcode || ''}
                onChange={handleChange}
                errorMsg={errors.zipcode}
                required
              />
            </Col>
            <Col md="12">
              <Form.Group controlId="acceptTerms" className="mb-3">
                <Form.Check
                  custom
                  type="checkbox"
                  label={<Fragment>By clicking here you agree to the <Link to="#" onClick={() => terms()}>terms and conditions</Link>.</Fragment>}
                  name="acceptTerms"
                  checked={values.acceptTerms === undefined ? false : values.acceptTerms}
                  onChange={(event) => handleSelectChange(event.target.checked, 'acceptTerms')}
                  isInvalid={!!errors.acceptTerms}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <FormSubmit
            block
            type="submit"
            variant="primary"
            btnText="Join Now"
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
}
 
export default PopupJoin;