export default function validate(values, required) {
  let errors = {};

  if (required.first_name && !values.first_name) {
    errors.first_name = 'First name is required';
  }

  if (required.last_name && !values.last_name) {
    errors.last_name = 'Last name is required';
  }
  if (required.name && !values.name) {
    errors.name = 'Name is required';
  }

  if (required.email) {
    if (!values.email) {
      errors.email = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is not valid';
    }
  }

  if (required.phone && !values.phone) {
    errors.phone = 'Phone number is required';
  }

  if (required.businessName && !values.businessName) {
    errors.businessName = 'Business name is required';
  }


  if (required.address1 && !values.address1) {
    errors.address1 = 'Address is required';
  }

  if (required.city && !values.city) {
    errors.city = 'City is required';
  }

  if (required.state && !values.state) {
    errors.state = 'Choose a state';
  }

  if (required.zipcode && !values.zipcode) {
    errors.zipcode = 'Zipcode is required';
  }

  return errors;
}