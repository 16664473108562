import { useState, useEffect } from 'react';

function useForm(callback, validate) {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  // Validate errors using useEffect hook
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      callback();
    }
    
    // eslint-disable-next-line
  }, [errors]);

  // Input change handler
  function handleChange(event) {
    const { name, value, required } = event.target;
    setValues({ ...values, [name]: value });
    setErrors({ 
      ...errors, 
      [name]: validate({[name]: value}, {[name]: required})[name] 
    });
  }

  // Select change handler
  const handleSelectChange = (event, field) => {
    setValues({ ...values, [field]: event });
    setErrors({ 
      ...errors, 
      [field]: validate({[field]: event}, {[field]: true})[field] 
    });
  }

  // Form submit handler
  function handleSubmit(event) {
    if (event) event.preventDefault();
    const { elements } = event.target;
    let required = {};

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].tagName === 'INPUT' || elements[i].tagName === 'TEXTAREA') {
        required[elements[i].name] = elements[i].required;
      }
    }
    
    setErrors(validate(values, required));
  }

  const handleFormReset = () => {
    setValues({});
    setErrors({});
  }

  // Return values, errors and handlers
  return {
    values,
    errors,
    setValues,
    handleChange,
    handleSelectChange,
    handleSubmit,
    handleFormReset
  }
}

export default useForm;