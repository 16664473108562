import React from 'react';
import { Popover, OverlayTrigger, Image, Button } from 'react-bootstrap';

import securityHint from '../../assets/images/security-hint.png';

const popover = (
  <Popover id="SecurityHit" className="CardMethod-Security__Hint shadow">
    <Popover.Content>
      <div className="text-center p-3">
        <Image fluid src={securityHint} />
        <p className="text-dark mt-2 mb-0">The 3-digit number located on the back right side of your card.</p>
      </div>
    </Popover.Content>
  </Popover>
);

const SecurityMore = () => {
  return (
    <OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose>
      <Button className="CardMethod-Security__Hint--Btn">?</Button>
    </OverlayTrigger>
  );
}
 
export default SecurityMore;