import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

const InputSelect = (props) => {
  const { label, labelIsHide, type, name, placeholder, errorMsg, required, ...rest } = props;

  useEffect(() => {
    let element = document.getElementsByName(props.name)[0];
    if (required) {
      element.setAttribute('required', true);
    }
  });

  return (
    <Form.Group controlId={name}>
      <Form.Label className={labelIsHide ? 'sr-only' : ''}>{label}</Form.Label>
      <Select 
        {...rest} 
        name={name}
        instanceId={name}
        placeholder={placeholder}
        classNamePrefix="NextSelect"
        className={`NextSelect${errorMsg ? ' is-invalid' : ''}`}
      />
      <Form.Control.Feedback type="invalid">
        {errorMsg}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export default InputSelect;