import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import axios from 'axios';

import Layout from '../components/Layout';
import { InputSelect, InputTextarea } from '../components/common';
import { formatDate } from '../utils';
import { serviceIssueOptions, parkingSpotOptions } from '../staticData';
import useForm from '../components/form/useForm';
import useCommit from '../utils/useCommit';
import validate from '../components/form/BookingValidation';
import BookingOverview from '../components/BookingOverview';

const BookingAdd = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [timeOptions, setTimeOptions] = useState([]);
  const [discretion, setDiscretion] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [bookingDiscount, setBookingDiscount] = useState(0);
  const { scrollToTop, setMessage, AlertMessage, setIsCommitting, FormSubmit } = useCommit();

  useEffect(() => {
    setIsLoaded(false);
    axios.get('/api/customers/booking-services')
      .then(res => {
        let data = [];
        for (let i = 0; i < res.data.data.length; i++) {
          data[i] = {
            value: res.data.data[i].service_name,
            label: res.data.data[i].service_name
          }
        }
        setServiceOptions(data);
        setIsLoaded(true);
      })
      .catch(error => console.log(error));

      availableTime();
  }, []);

  const availableTime = (dateStr) => {
    const date = dateStr ? formatDate(dateStr) : formatDate(new Date());
    if (date) {
      setTimeOptions([]);
      axios.get('/api/customers/available-booking-time', {
          params: { date }
        })
        .then(res => {
          setTimeOptions(res.data);
        })
        .catch(error => console.log(error));
    }
  }

  const bookingPreview = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      setIsCommitting(true);
      const {
        serviceName,
        bookedDate,
        bookingTime,
        isCarParking,
        parkingSpot
      } = values;
      axios.post('/api/customers/pre-booking-data', {
        serviceName, 
        bookingDate: bookedDate ? formatDate(bookedDate) : formatDate(new Date()), 
        bookingTime, 
        parking: isCarParking,
        parkingSpot,
      })
      .then(res => {
        setDiscretion(res.data);
        setDiscount(res.data.autoDiscount);
        setBookingDiscount(res.data.bookingDiscount);
        setIsCommitting(false);
        setModalShow(true);
      })
      .catch(error => console.log(error));
    }
  }

  const bookingCallback = (data) => {
    if (data === 'success') {
      availableTime();
      scrollToTop();
      handleFormReset();
      setModalShow(false);
      setMessage ({
        type: 'success',
        text: 'Booking has been made successfully!'
      });
    }
  }

  const bookingCalculationCallback = (data) =>{
    if (data.status === 'success') {
      setDiscretion(data.data);
      setDiscount(data.data.couponDiscount);
    }
  }

  const { 
    values, 
    errors, 
    handleChange, 
    handleSelectChange,
    handleSubmit,
    handleFormReset
  } = useForm(bookingPreview, validate);

  return (
    <Layout dataIsLoaded={isLoaded}>
      <div className="Dashboard-ContentBody__Header">
        <h1 className="Dashboard-ContentBody__Header--Title">
          Add New Appointment
        </h1>
      </div>
      <div className="Dashboard-ContentBody__Form">
        <Row>
          <Col md="10">
            <AlertMessage />
            <Card className="shadow-sm">
              <Card.Body>
                <Form onSubmit={handleSubmit} noValidate>
                  <h5 className="text-secondary mb-3">Appointment details</h5>
                  <Row>
                    <Col md="6">
                      <Form.Group controlId="bookedDate">
                        <Form.Label>Booking date</Form.Label>
                        <DatePicker 
                          id="bookedDate"
                          className="form-control"
                          placeholderText="Select a booking date"
                          dateFormat="MMMM d, yyyy"
                          minDate={new Date()}
                          selected={values.bookedDate || new Date()}
                          onChange={(event) => {handleSelectChange(event, 'bookedDate'); availableTime(event);}}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <InputSelect
                        name="bookingTime"
                        label="Booking time"
                        placeholder={timeOptions.length > 0 ? 'Select a booking time' : 'Please wait...'}
                        options={timeOptions}
                        value={timeOptions.find(item => item.value === values.bookingTime) || ''}
                        onChange={(event) => handleSelectChange(event.value, 'bookingTime')}
                        errorMsg={errors.bookingTime}
                        required={true}
                      />
                    </Col>
                    <Col md="12">
                      <InputSelect
                        name="serviceName"
                        label="Service name"
                        placeholder="Make a selection"
                        options={serviceOptions}
                        value={serviceOptions.find(item => item.value === values.serviceName) || ''}
                        onChange={(event) => handleSelectChange(event.value, 'serviceName')}
                        errorMsg={errors.serviceName}
                        required={true}
                      />
                    </Col>
                    
                    <Col md="6">
                      <Form.Group controlId="carParking" className="mb-3">
                        <div className={errors.isCarParking ? 'is-invalid' : ''}>
                          <Form.Label className="w-100">Do you have a parking spot?</Form.Label>
                          <Form.Check
                            custom
                            inline
                            type="radio"
                            label="Yes"
                            id="carParkingYes"
                            name="isCarParking"
                            checked={values.isCarParking === undefined ? false : values.isCarParking === 1 ? true : false}
                            onChange={() => handleSelectChange(1, 'isCarParking')}
                          />
                          <Form.Check
                            custom
                            inline
                            type="radio"
                            label="No"
                            id="carParkingNo"
                            name="isCarParking"
                            checked={values.isCarParking === undefined ? false : values.isCarParking === 0 ? true : false}
                            onChange={() => handleSelectChange(0, 'isCarParking')}
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.isCarParking}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      {
                        values.isCarParking === 1 &&
                          <InputSelect
                            name="parkingSpot"
                            label="Parking spot"
                            placeholder="Select parking spot"
                            options={parkingSpotOptions}
                            value={parkingSpotOptions.find(item => item.value === values.parkingSpot) || ''}
                            onChange={(event) => handleSelectChange(event.value, 'parkingSpot')}
                            errorMsg={errors.parkingSpot}
                            required={true}
                          />
                      }
                    </Col>
                  </Row>
                  <InputSelect
                    name="issue"
                    label="Describe the issues (Please provide as much information as possible)"
                    placeholder="Select OS/Platform/Device"
                    options={serviceIssueOptions}
                    value={serviceIssueOptions.find(item => item.value === values.issue) || ''}
                    onChange={(event) => handleSelectChange(event.value, 'issue')}
                    errorMsg={errors.issue}
                    required={true}
                  />
                  <InputTextarea
                    rows={3}
                    name="notes"
                    label="Notes"
                    labelIsHide={true}
                    placeholder="Describe all your issue here"
                    value={values.notes || ''}
                    onChange={handleChange}
                    errorMsg={errors.notes}
                    required
                  />
                  <FormSubmit
                    type="submit"
                    variant="primary"
                    btnText="Proceed to Checkout"
                  />
                </Form>
                <BookingOverview
                  data={values}
                  calculation={discretion}
                  calculationModal={modalShow}
                  discount={discount}
                  bookingDiscount={bookingDiscount}
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  bookingCalculationResCallback={bookingCalculationCallback}
                  bookingResponseCallback={bookingCallback}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default BookingAdd;