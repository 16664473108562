import React, { useEffect } from 'react';

import Invoice from '../components/Invoice';

const InvoicePrint = () => {
  useEffect(() => {
    window.print();
  }, []);

  return (
    <Invoice />
  );
}
 
export default InvoicePrint;