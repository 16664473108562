import React, { useState } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import axios from 'axios';

import Layout from '../components/Layout';
import { InputField } from '../components/common';
import useForm from '../components/form/useForm';
import validate from '../components/form/PasswordValidation';
import useCommit from '../utils/useCommit';

const ChangePassword = () => {
  const { setIsCommitting, FormSubmit, setMessage, AlertMessage } = useCommit();
  const [passwordMatched, setPasswordMatched] = useState('');

  const updatePassword = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      setIsCommitting(true);
      const { currentPassword, password } = values;
      const data = {
        current_password: currentPassword,
        password: password
      };

      axios.post('/api/customers/change-password', data)
        .then(res => {
          setMessage({
            type: res.data.success === 0 ? 'danger' : 'success',
            text: res.data.message
          });
          setIsCommitting(false);
          handleFormReset();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  const { 
    values, 
    errors, 
    handleChange, 
    handleSubmit, 
    handleFormReset
  } = useForm(updatePassword, validate);

  const handleMatching = () => {
    const { password, confirmPassword } = values;
    if (password === confirmPassword) {
      setPasswordMatched('');
    } else {
      setPasswordMatched('New password doesn\'t match');
    }
  }

  return (
    <Layout dataIsLoaded={true}>
      <div className="Dashboard-ContentBody__Header">
        <h1 className="Dashboard-ContentBody__Header--Title">
          Change Password
        </h1>
      </div>
      <div className="Dashboard-ContentBody__Form">
        <Row>
          <Col md="7">
            <AlertMessage />
            <Card className="shadow-sm">
              <Card.Body>
                <Form onSubmit={handleSubmit} noValidate>
                  <InputField
                    type="password"
                    name="currentPassword"
                    label="Current password"
                    value={values.currentPassword || ''}
                    onChange={handleChange}
                    errorMsg={errors.currentPassword}
                    required
                  />
                  <InputField
                    type="password"
                    name="password"
                    label="New password"
                    value={values.password || ''}
                    onChange={handleChange}
                    errorMsg={errors.password}
                    required
                  />
                  <InputField
                    type="password"
                    name="confirmPassword"
                    label="Re-type new password"
                    value={values.confirmPassword || ''}
                    onChange={handleChange}
                    onKeyUp={handleMatching}
                    errorMsg={errors.confirmPassword || passwordMatched}
                    required
                  />
                  <FormSubmit
                    type="submit"
                    variant="primary"
                    btnText="Change Password"
                  />
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default ChangePassword;