export default function validate(values) {
  let errors = {};

  if (!values.cardNumber) {
    errors.cardNumber = 'Card number is required';
  }

  if (!values.cardholderName) {
    errors.cardholderName = 'Cardholder name is required' ;
  }

  if (!values.expiryMonth) {
    errors.expiryMonth = 'Month required';
  }

  if (!values.expiryYear) {
    errors.expiryYear = 'Year required';
  }

  if (!values.securityCode) {
    errors.securityCode = 'Security code is required';
  }

  return errors;
}