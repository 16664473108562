import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import instagramIcon from '../assets/images/instagram.png';
import youtubeIcon from '../assets/images/youtube.png';

const Footer = () => {
  return (
    <footer className="Footer">
      <Container>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <Row>
              <Col xs="6" md="4">
                <div className="Footer-Widget">
                  <h2 className="Footer-Heading">Get Started</h2>
                  <ul className="Footer-NavBar">
                    <li>
                      <a href="!#">Home</a>
                    </li>
                    <li>
                      <a href="!#">About</a>
                    </li>
                    <li>
                      <a href="!#">Contact</a>
                    </li>
                    <li>
                      <a href="!#">FAQs</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs="6" md="4">
                <div className="Footer-Widget">
                  <h2 className="Footer-Heading">Quick as</h2>
                  <ul className="Footer-NavBar">
                    <li>
                      <a href="!#">Services</a>
                    </li>
                    <li>
                      <a href="!#">Customer Reviews</a>
                    </li>
                    <li>
                      <a href="!#">Service Area</a>
                    </li>
                    <li>
                      <a href="!#">Join Our Team</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs="6" md="4">
                <div className="Footer-Widget Footer-Widget__Last">
                  <h2 className="Footer-Heading">Legal</h2>
                  <ul className="Footer-NavBar">
                    <li>
                      <a href="!#">Refunds</a>
                    </li>
                    <li>
                      <a href="!#">Terms of Use</a>
                    </li>
                    <li>
                      <a href="!#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="!#">Disclaimer</a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md="12">
            <div className="Footer-Copyright">
              <Row>
                <Col md="6">
                  <ul className="Footer-SocialMedia">
                    <li>
                      <a href="!#" target="_blank">
                        <img src={instagramIcon} alt="Instagram" title="Follow us on Instagram" className="img-fluid" />
                      </a>
                    </li>
                    <li>
                      <a href="!#" target="_blank">
                        <img src={youtubeIcon} alt="YouTube" title="Follow us on YouTube" className="img-fluid" />
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col md="6">
                  <p className="text-secondary text-center text-md-right mt-2 mb-0">
                    &copy; {new Date().getFullYear()} Geeks Sydney Pty Ltd | ABN: 59616635664
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;