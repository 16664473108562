import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import axios from 'axios';

import { AuthContext } from './context/auth';
import PrivateRoute from './utils/PrivateRoute';
import ScrollIntoView from './utils/ScrollIntoView';
import {
  SignIn,
  ForgetPassword,
  ResetPassword,
  LandingPage,
  Dashboard,
  Appointments,
  Invoices,
  InvoicePreview,
  InvoicePrint,
  BookingAdd,
  Licenses,
  Discounts,
  Refers,
  Users,
  UserAdd,
  UserEdit,
  UserRole,
  BillingSummary,
  ManageBank,
  ProfileEdit,
  Addresses,
  ChangePassword,
  NotFound
} from './pages';

const App = () => {
  const existAuth = JSON.parse(localStorage.getItem('user'));
  const [auth, setAuth] = useState(existAuth);

  const setTokens = (data) => {
    localStorage.setItem('user', JSON.stringify(data));
    setAuth(data);
  }

  const signOut = () => {
    localStorage.removeItem('user');
    setAuth();
    window.location.href = '/';
  }

  return (
    <AuthContext.Provider value={{
      auth,
      setAuth: setTokens,
      signOut
    }}>
      <Router>
        <ScrollIntoView>
          <Switch>
            <Route path="/" component={LandingPage} exact />
            <Route path="/signin" component={SignIn} />
            <Route path="/forget-password" component={ForgetPassword} />
            <Route path="/reset-password/:code" component={ResetPassword} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/appointment/new" component={BookingAdd} />
            <PrivateRoute path="/appointments" component={Appointments} />
            <PrivateRoute path="/coupon-discounts" component={Discounts} />
            <PrivateRoute path="/refer-rewards" component={Refers} />
            <PrivateRoute path="/invoices" component={Invoices} />
            <PrivateRoute path="/invoice/:id" component={InvoicePreview} exact />
            <PrivateRoute path="/invoice/print/:id" component={InvoicePrint} />
            <PrivateRoute path="/addresses" component={Addresses} />
            <PrivateRoute path="/licenses" component={Licenses} />
            <PrivateRoute path="/users" component={Users} />
            <PrivateRoute path="/user/new" component={UserAdd} />
            <PrivateRoute path="/user/edit/:id" component={UserEdit} />
            <PrivateRoute path="/user/permissions/:id" component={UserRole} />
            <PrivateRoute path="/income-summary" component={BillingSummary} />
            <PrivateRoute path="/manage-bank" component={ManageBank} />
            <PrivateRoute path="/profile/edit" component={ProfileEdit} />
            <PrivateRoute path="/profile/change-password" component={ChangePassword} />
            <Route path="*" component={NotFound} />
          </Switch>
        </ScrollIntoView>
      </Router>
    </AuthContext.Provider>
  );
}
 
export default App;
