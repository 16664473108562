import React, { Fragment } from 'react';
import { Modal, Row, Col, Image } from 'react-bootstrap';
import Moment from 'react-moment';

const AppointmentView = (props) => {
  const { data, ...rest } = props;
  return (
    <Modal
      {...rest}
      centered
      backdrop="static"
      dialogClassName="AppointmentView"
      aria-labelledby="AppointmentView"
    >
      {
        data !== undefined &&
          <Fragment>
            <Modal.Header closeButton>
              <Modal.Title id="AppointmentView">
                <span className="text-secondary">Ref. ID</span> #{data.booking_id}<br />
                <span className="text-secondary">Placed On:</span> <Moment date={data.created_at} format="DD, MMM YYYY" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12}>
                  <h6 className = "text-dark"> <strong> Your Notes:</strong> {data.app_notes}</h6>
                  {
                    data.work_status &&
                    <div>
                      <h6 className = "text-dark"><strong>Problem Solved:</strong> <span className="text-capitalize">{data.work_status.prob_status}</span></h6>
                      <h6 className = "text-dark"><strong>Tech Notes:</strong> {data.work_status.notes}</h6>
                      <h6 className = "text-dark"> <strong> Sinature: </strong> 
                      <Image fluid src={process.env.REACT_APP_API_URI+'/'+data.work_status.signature} alt="customer-signature" width="180" />
                      </h6>
                    </div>
                  }
                </Col>
              </Row>
            </Modal.Body>
          </Fragment>
      }
    </Modal>
  );
  
}
 
export default AppointmentView;