import React from 'react';

import { usePayment } from '../../context/payment';
import useCommit from '../../utils/useCommit';

const CashMethod = (props) => {
  const { acceptTerms, setAcceptTerms, checkoutHandler } = usePayment();
  const { setIsCommitting, FormSubmit } = useCommit();
  const { settings } = props;

  const handleSubmit = () => {
    if (acceptTerms.isActive) {
      checkoutHandler(); 
      setIsCommitting(true);
    } else {
      setAcceptTerms({
        isActive: false,
        error: true
      })
    }
  };
  return (
    <div className="CashMethod">
      {
        settings.weekend_offer ?
          <p className="text-info mb-3">{`Pay now and get ${settings.booking_discount}% discount for weekdays and ${settings.weekend_offer_amo}% discount for weekends appointment.`}</p> 
          : <p className="text-info mb-3">{`You are receiving ${settings.weekend_offer}% off for booking online.`}</p>
      }
      <FormSubmit
        block
        size="lg"
        variant="primary"
        btnText="Make Payment"
        onClick={() => handleSubmit()}
      />
    </div>
  );
}
 
export default CashMethod;