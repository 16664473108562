import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../context/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const existAuth = JSON.parse(localStorage.getItem('user'));
  const { auth } = useAuth();
  let authIsValid = false;
  if (auth !== null && existAuth !== null) {
    authIsValid = JSON.stringify(auth) === JSON.stringify(existAuth);
  }

  return (
    <Route
      {...rest}
      render={props =>
        authIsValid ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default PrivateRoute;