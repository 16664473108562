import React, { Fragment } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Moment from 'react-moment';

const BookingView = (props) => {
  const { data, ...rest } = props;
  
  return (
    <Modal
      {...rest}
      centered
      backdrop="static"
      dialogClassName="BookingView"
      aria-labelledby="BookingView"
    >
      {
        data !== undefined &&
          <Fragment>
            <Modal.Header closeButton>
              <Modal.Title id="BookingView">
                <span className="text-secondary">Booking ID: </span> #{data.booking_id}<br />
                <span className="text-secondary">Placed On: </span> <Moment date={data.created_at} format="DD, MMM YYYY" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12}>
                  <h6 className="text-dark"><strong>Your Notes:</strong> {data.notes}</h6>
                </Col>
              </Row>
            </Modal.Body>
          </Fragment>
      }
    </Modal>
  );
  
}
 
export default BookingView;