import React from 'react';

const CardIcons = () => (
  <div className="CardMethod-CardIcons">
    <span className="CardMethod-CardIcons__visa"></span>
    <span className="CardMethod-CardIcons__mastercard"></span>
    <span className="CardMethod-CardIcons__amex"></span>
    <span className="CardMethod-CardIcons__discover"></span>
  </div>
);
 
export default CardIcons;