import React, { useState, useEffect } from 'react';
import { Row, Col} from 'react-bootstrap';
import axios from 'axios';

import Layout from '../components/Layout';
import Invoice from '../components/Invoice';

const InvoicePreview = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [invoice, setInvoice] = useState('');

  useEffect(() => {
    var id = props.match.params.id;
    axios.get('api/customers/invoices/view/'+id).then(res =>{
      setInvoice(res.data);
      setIsLoaded(true);
    }).catch(error => {

    });
  }, []);

  const printInvoice = () => {
    var divElements = document.getElementById("odrDtlsPrnt").innerHTML;
    var oldPage = document.body.innerHTML;
    document.body.innerHTML =
      "<html><head><title></title></head><body>" +
      divElements + "</body>";
    window.print();
    document.body.innerHTML = oldPage;
  }

  return (
    <Layout dataIsLoaded={isLoaded}>
      {
        Object.keys(invoice).length > 0 ?
        <div>
          <div className="Dashboard-ContentBody__Header">
            <Row>
              <Col md="6">
                <h1 className="Dashboard-ContentBody__Header--Title">
                  Invoice #{invoice.invoice_id}
                </h1>
              </Col>
              <Col md="6">
                <div className="w-100 text-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={ printInvoice }
                  >Print</button>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} id="odrDtlsPrnt">
              <Invoice data={invoice}/>
            </Col>
          </Row>
        </div>
        :
        <div>
          <h3 className="text-center mt-5 text-danger">Sorry! You don't have permission to access.</h3>
        </div>
      }
    </Layout>
  );
}
 
export default InvoicePreview;