import React from 'react';
import { Home, Calendar, Clipboard, MapPin, Book, Sliders} from 'react-feather';
import { NavLink } from 'react-router-dom';

const Navigation = () => {
  const iconSize = 24;
  return (
    <ul className="Dashboard-ContentSidebar__Nav">
      
      <li>
        <NavLink 
          activeClassName="active" 
          to="/dashboard">
          <Home size={iconSize} /> Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink 
          activeClassName="active" 
          to="/appointment/new">
          <Calendar size={iconSize} /> Make an Appointment
        </NavLink>
      </li>
      <li>
        <NavLink 
          activeClassName="active" 
          to="/appointments">
          <Clipboard size={iconSize} /> Appointments
        </NavLink>
      </li>
      <li>
        <NavLink
          activeClassName="active"
          to="/invoices">
          <Book size={iconSize} /> Invoices
        </NavLink>
      </li>
      <li>
        <NavLink 
          activeClassName="active" 
          to="/licenses">
          <MapPin size={iconSize} /> Licenses
        </NavLink>
      </li>
      {/* <li>
        <NavLink 
          activeClassName="active" 
          to="/addresses">
          <Key size={iconSize} /> Address Book
        </NavLink>
      </li> */}
      <li>
        <NavLink 
          activeClassName="active" 
          to="/coupon-discounts">
          <Book size={iconSize} /> Coupon & Discount
        </NavLink>
      </li>
      <li>
        <NavLink 
          activeClassName="active" 
          to="/refer-rewards">
          <Sliders size={iconSize} /> Refer a Friend
        </NavLink>
      </li>
      {/* <li>
        <NavLink 
          activeClassName="active" 
          to="/manage-bank">
          <Sliders size={iconSize} /> Manage Bank
        </NavLink>
      </li>
      <li>
        <NavLink 
          activeClassName="active" 
          to="/report/all">
          <FileText size={iconSize} /> Reports
        </NavLink>
      </li> */}
    </ul>
  );
}

export default Navigation;