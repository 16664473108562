export const handleNumbersOnlyKeyDown = (event) => {
  let flag;

  if((event.keyCode === 8) ||
      (event.keyCode === 9) || 
      (event.keyCode === 16 && event.keyCode >= 9) ||
      (event.keyCode === 37) ||
      (event.keyCode === 39) ||
      (event.keyCode === 46) || 
      (event.keyCode >= 48 && event.keyCode <= 57) || 
      (event.keyCode >= 96 && event.keyCode <= 105)) {
    flag = false;
  } else {
    flag = true;
  }

  if(flag) {
    event.preventDefault();
  }
}

export const handleCardExpiryKeyUp = (event) => {
  let expiryDate = event.target.value;
  const year = new Date().getFullYear();
  const alterYear = year.toString().slice(2);

  if (event.keyCode === 8) {
    event.target.value = '';
  } else {
    if (expiryDate > 1 && expiryDate.length === 1) {
      event.target.value = '0' + expiryDate + '/';
    } else if (expiryDate.length === 2) {
      if (expiryDate < 13) {
        event.target.value = expiryDate + '/';
      } else {
        event.target.value = expiryDate.slice(0, 1);
      }
    } else if (expiryDate.length === 5) {
      let expiryYear = expiryDate.slice(3);
      if (expiryYear < alterYear) {
        event.target.value = expiryDate.slice(0, 3);
      }
    }
  }
}

export const getCardType = (number) => {
  if (number !== '' && number !== null && number !== undefined) {
    const amexReg   = new RegExp('^3[47]');
    const jbcReg    = new RegExp('^35(2[89]|[3-8][0-9])');
    const masterReg = new RegExp('^5[1-5][0-9]');
    const visaReg   = new RegExp('^4');

    if (number.toString().match(amexReg)) {
      return 'amex';
    } else if (number.toString().match(jbcReg)) {
      return 'jcb';
    } else if (number.toString().match(masterReg)) {
      return 'master-card';
    } else if (number.toString().match(visaReg)) {
      return 'visa';
    } else {
      return 'invalid';
    }
  }
}

export const ccType = (number) => {
  let result = 'unknown';

  if (/^5[1-5]/.test(number)) {
    result = 'mastercard';
  } else if (/^4/.test(number)) {
    result = 'visa';
  } else if (/^3[47]/.test(number)) {
    result = 'amex';
  } else if (/^6[0-5]/.test(number)) {
    result = 'discover';
  }

  return result;
}

export const removeWhiteSpace = (value) => {
  if (value !== undefined) {
    return value.replace(/ /g,'');
  }
}

export const ccFormat = (value) => {
  if (value !== undefined) {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];

    for (let i=0, len=match.length; i<len; i+=4) {
      parts.push(match.substring(i, i+4))
    }

    if (parts.length) {
      return parts.join(' ')
    } else {
      return value
    }
  }
}

export const formatDate = (dateStr) => {
  if (dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return year + '-' + month + '-' + day;
  }
}

export const expiryMonthOptions = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

export const expiryYearOptions = () => {
  const currYear = new Date().getFullYear();
  const targetYear = parseInt(currYear + 30);
  let years = [];

  for (let i = currYear; i < targetYear; i++) {
    years.push({ value: i.toString().slice(2), label: i });
  }

  return years;
}