import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Badge, Dropdown } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Layout from '../components/Layout';

const Discounts = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [offers, setOffers] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [rewards, setRewards] = useState([]);


  const fetchData = (pageNumber) => {
    axios.get('/api/customers/coupon-discounts', {
        params: { page: pageNumber }
      })
      .then(res => {
        console.log(res.data);
        setOffers(res.data.offers);
        setCoupons(res.data.coupons);
        setRewards(res.data.rewards);
        setIsLoaded(true);
      })
      .catch(error => console.log(error));
  }


  useEffect(() => {
    fetchData(1);
  }, []);


  return (
    <Layout dataIsLoaded={isLoaded}>
      {
        offers.length > 0 &&
        <div>
          <div className="Dashboard-ContentBody__Header">
            <Row className="mt-5">
              <Col md="6">
                <h1 className="Dashboard-ContentBody__Header--Title">
                  Special Offer
                </h1>
              </Col>
            </Row>
          </div>
          <div className="Dashboard-ContentBody__TableData">
            <Row>
              <Col>
                <Card className="shadow-sm border-0">
                  <Card.Body>
                    <Table responsive>
                      <colgroup>
                        <col width="auto" />
                        <col width="25%" />
                        <col width="25%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Offer</th>
                          <th>Discount Code</th>
                          <th>Expire Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          offers.map(offer => (
                            <tr key={offer.id}>
                              <td>
                                <strong>{offer.offer_name}</strong>
                              </td>
                              <td>
                                { offer.discount_code }
                              </td>
                              <td>
                                <Moment date={offer.expire_date} format="ddd, DD MMM YYYY" />
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      }

      <div className="Dashboard-ContentBody__Header">
        <Row className="mt-5">
          <Col md="6">
            <h1 className="Dashboard-ContentBody__Header--Title">
              Refer Rewards
            </h1>
          </Col>
        </Row>
      </div>
      <div className="Dashboard-ContentBody__TableData">
        <Row>
          <Col>
            <Card className="shadow-sm border-0">
              <Card.Body>
                <Table responsive>
                  <colgroup>
                    <col width="auto" />
                    <col width="30%" />
                    <col width="14%" />
                    <col width="25%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Coupon Code / Auto Apply</th>
                      <th>Discount</th>
                      <th>Used</th>
                      <th className="totalCol">Expired date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      rewards.map(reward => (
                        <tr key={reward.id}>
                          <td>
                            {
                                <strong>{reward.coupon}</strong>
                            }
                          </td>
                          <td>
                            {reward.discount} %
                          </td>
                          <td>
                            {reward.cust_use === 1 ? 'Used' : 'Not Used'}
                          </td>
                          <td className="totalCol">
                            {
                              reward.expire_date ?
                              <Moment date={reward.expire_date} format="ddd, DD MMM YYYY" />:
                              'Unlimited'
                            }
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="Dashboard-ContentBody__Header">
        <Row className="mt-5">
          <Col md="6">
            <h1 className="Dashboard-ContentBody__Header--Title">
              Coupon & Discount
            </h1>
          </Col>
        </Row>
      </div>
      <div className="Dashboard-ContentBody__TableData">
        <Row>
          <Col>
            <Card className="shadow-sm border-0">
              <Card.Body>
                <Table responsive>
                  <colgroup>
                    <col width="auto" />
                    <col width="30%" />
                    <col width="14%" />
                    <col width="25%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Coupon Code / Auto Apply</th>
                      <th>Discount</th>
                      <th>Used</th>
                      <th className="totalCol">Expired date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      coupons.map(coupon => (
                        <tr key={coupon.id}>
                          <td>
                            {
                              coupon.auto_apply === 'yes' ?
                              <strong>Auto Apply</strong> :
                              <strong>{coupon.coupon}</strong>
                            }
                          </td>
                          <td>
                            {coupon.discount} %
                          </td>
                          <td>
                            {coupon.is_used === 1 ? 'Used' : 'Not Used'}
                          </td>
                          <td className="totalCol">
                            <Moment date={coupon.expire_date} format="ddd, DD MMM YYYY" />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default Discounts;