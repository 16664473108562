import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { ArrowUp, ArrowDown } from 'react-feather';

const DashboardCard = (props) => {
  return (
    <Col sm="6" lg="4">
      <Card className="Dashboard-ContentBody__CardItem shadow-sm border-0 mb-3">
        <Card.Body>
          <Row>
            <Col xs={8}>
              <h3 className="Dashboard-ContentBody__CardItem--Header">{props.title}</h3>
              <h3 className="Dashboard-ContentBody__CardItem--CountItem">{props.count}</h3>
            </Col>
            <Col xs={4}>
              <div className="Dashboard-ContentBody__CardItem--IconBox shadow-sm">
                {props.icon}
              </div>
            </Col>
          </Row>
          {
            props.comData &&
              <span className={props.comData > 0 ? 'small text-success' : 'small text-danger'}>
                {props.comData > 0 ? <ArrowUp /> : <ArrowDown />} {props.comData}
              </span>
          }
        </Card.Body>
      </Card>
    </Col>
  );
}

export default DashboardCard;