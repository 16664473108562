import React, { useState } from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import { Star } from 'react-feather';
import axios from 'axios';

import { InputTextarea } from './common';
import useForm from './form/useForm';
import validate from './form/RatingValidation';
import useCommit from '../utils/useCommit';

const Reviewing = (props) => {
  const { data, ...rest } = props;
  const { setIsCommitting, FormSubmit, setMessage, AlertMessage } = useCommit();
  const [rating, setRating] = useState(0);
  const [ratingError, setRatingError] = useState(false);

  const handleRating = (value) => {
    if (rating >= 1 && value === 1) {
      setRating(0);
      handleSelectChange(value, 'rating');
    } else {
      setRating(value);
      handleSelectChange(value, 'rating');
    }
  }

  const userReview = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      if (rating < 1) {
        setRatingError(true);
      } else {
        setRatingError(false);
        setIsCommitting(true);
        axios.post('/api/customers/rating', {
          appId: data.id,
          rating: values.rating,
          comments: values.comments
        }).then(res =>{
         setMessage({
           type: 'success',
           text: res.data.message
         });
         setIsCommitting(false);
        }).catch(error => {

        });
        
      }
    }
  }

  const { 
    values, 
    errors, 
    handleChange, 
    handleSelectChange,
    handleSubmit 
  } = useForm(userReview, validate);

  return (
    <Modal
      {...rest}
      centered
      backdrop="static"
      dialogClassName="Review"
      aria-labelledby="Review"
    >
      <Modal.Header closeButton>
        <Modal.Title id="Reviewing">Review us</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <h4>Satisfied with our service? Give us a review!</h4>
            <p>We would love to hear your feedback so that we can further improve and suit to your needs. It will only take a spare minute of your time!</p>
            <Form onSubmit={handleSubmit} noValidate>
              <AlertMessage />
              <Form.Group controlId="RatingStar">
                <Form.Label as="h5">How would you rate us?</Form.Label>
                <div className={ratingError ? 'd-flex is-invalid' : 'd-flex'}>
                  <span 
                    className="RatingStar"
                    onClick={() => handleRating(1)}
                  ><Star color={rating > 0 ? '#F97807' : '#212529'} /></span>
                  <span 
                    className="RatingStar"
                    onClick={() => handleRating(2)}
                  ><Star color={rating > 1 ? '#F97807' : '#212529'} /></span>
                  <span 
                    className="RatingStar"
                    onClick={() => handleRating(3)}
                  ><Star color={rating > 2 ? '#F97807' : '#212529'} /></span>
                  <span 
                    className="RatingStar"
                    onClick={() => handleRating(4)}
                  ><Star color={rating > 3 ? '#F97807' : '#212529'} /></span>
                  <span 
                    className="RatingStar"
                    onClick={() => handleRating(5)}
                  ><Star color={rating > 4 ? '#F97807' : '#212529'} /></span>
                </div>
                <div className="invalid-feedback">Rating is required</div>
              </Form.Group>
              <InputTextarea
                rows={3}
                name="comments"
                label="Your comments"
                value={values.comments || ''}
                onChange={handleChange}
                errorMsg={errors.comments}
                required
              />
              <FormSubmit
                type="submit"
                variant="primary"
                btnText="Submit Review"
              />
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
  
}
 
export default Reviewing;