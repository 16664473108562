import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import Loader from '../components/Loader';

const Layout = (props) => {
  return (
    <div className="Dashboard">
      <Header />
      <main className="Dashboard-ContentArea">
        <Container>
          <Row>
            <Col md="4" lg="3">
              <aside className="Dashboard-ContentSidebar shadow-sm rounded">
                <Navigation />
              </aside>
            </Col>
            <Col md="8" lg="9">
              <div className="Dashboard-ContentBody">
                {
                  props.dataIsLoaded ?
                    props.children : <Loader />
                }
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </div>
  );
}

export default Layout;