import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useAuth } from '../context/auth';
import { InputField } from './common';
import useForm from './form/useForm';
import validate from './form/LoginValidation';
import useCommit from '../utils/useCommit';
import axios from 'axios';

const PopupSignin = (props) => {
  const { parentcallback, ...rest } = props;
  const { setAuth } = useAuth();
  const { setIsCommitting, FormSubmit, setMessage, AlertMessage } = useCommit();

  const userSignIn = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      setIsCommitting(true);
      const {
        email,
        password
      } = values;
      const data = {
        email,
        password
      };
      axios.post('/api/customers/signin', data).then(res => {
          if (res.data.status === 204) {
            setMessage({
              type: 'danger',
              text: res.data.message
            });
            setIsCommitting(false);
          }
          if (res.data.status === 0) {
            const user = {
              user_id: res.data.data.id
            }
            axios.post('/api/customers/token-create', user).then(res => {
                if (res.data.status === 'success') {
                  checkAuthToken(res.data, password);
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
          if (res.data.status === 1) {
            checkAuthToken(res.data, password);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  const checkAuthToken = (data, password) => {
    if (Object.keys(data).length > 0) {
      const secrets = {
        client_id: data.data.id,
        client_secret: data.data.secret,
        grant_type: 'password',
        username: data.data.user.email,
        password: password,
        scope: '*'
      }
      axios.post('/oauth/token', secrets).then(res => {
          setAuth({
            access_token: res.data.access_token,
            id: data.data.user.id,
            name: data.data.user.name,
            role: data.data.user.role,
            permission: data.permission
          });
          window.location.href = '/dashboard';
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  const { 
    values, 
    errors, 
    handleChange, 
    handleSubmit 
  } = useForm(userSignIn, validate);

  return (
    <Modal
      {...rest}
      size="sm"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Sign In Now</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} noValidate>
          <AlertMessage />
          <InputField
            type="text"
            name="email"
            label="Email address"
            value={values.email || ''}
            onChange={handleChange}
            errorMsg={errors.email}
            required
          />
          <InputField
            type="password"
            name="password"
            label="Password"
            value={values.password || ''}
            onChange={handleChange}
            errorMsg={errors.password}
            required
          />
          <FormSubmit
            block
            type="submit"
            variant="primary"
            btnText="Sign In"
          />
          <Link 
            to="#" 
            className="mt-2 d-inline-block"
            onClick={ () => parentcallback() }
          >Forgot password?</Link>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
 
export default PopupSignin;