import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import axios from 'axios';

import Layout from '../components/Layout';
import { InputField, InputSelect } from '../components/common';
import { handleNumbersOnlyKeyDown } from '../utils';
import { statusOptions } from '../staticData';
import useForm from '../components/form/useForm';
import validate from '../components/form/UserRoleValidation';
import useCommit from '../utils/useCommit';

const UserAdd = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [userRoleOptions, setUserRoleOptions] = useState([]);
  const { scrollToTop, setIsCommitting, FormSubmit, setMessage, AlertMessage } = useCommit();

  useEffect(() => {
    setIsLoaded(false);
    axios.get('/api/partners/role')
      .then(res => {
        setUserRoleOptions(res.data.roleList);
        setIsLoaded(true);
      }).catch(error => {
        console.log(error)
      })

  }, []);
  
  const addRole = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      setIsCommitting(true);
      const { role, status, name, phone, email, password } = values;
      const data = {
        role,
        status, 
        name, 
        phone, 
        email, 
        password
      };

      axios.post('/api/partners/alias-user/add', data)
        .then(res => {
          if(res.data.status === 'success')
          {
            setMessage({
              type: 'success',
              text: res.data.message
            });
          }else
          {
            setMessage({
              type: 'danger',
              text: res.data.message
            });
          }
          setIsCommitting(false);
          scrollToTop();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  const { 
    values, 
    errors, 
    handleChange, 
    handleSelectChange,
    handleSubmit 
  } = useForm(addRole, validate);

  return (
    <Layout dataIsLoaded={isLoaded}>
      <div className="Dashboard-ContentBody__Header">
        <h1 className="Dashboard-ContentBody__Header--Title">
          Add New User
        </h1>
      </div>
      <div className="Dashboard-ContentBody__Form">
        <Row>
          <Col md="10">
            <AlertMessage/>
            <Card className="shadow-sm">
              <Card.Body>
                <Form onSubmit={handleSubmit} noValidate>
                  <h5 className="text-secondary mb-3">General details</h5>
                  <Row>
                    <Col md="6">
                      <InputSelect
                        name="role"
                        label="User role"
                        placeholder="Choose a role"
                        options={userRoleOptions}
                        onChange={(event) => handleSelectChange(event.value, 'role')}
                        errorMsg={errors.role}
                        required={true}
                      />
                    </Col>
                    <Col md="6">
                      <InputSelect
                        name="status"
                        label="Status"
                        placeholder="Choose a status"
                        options={statusOptions}
                        onChange={(event) => handleSelectChange(event.value, 'status')}
                        errorMsg={errors.status}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <h5 className="text-secondary mt-5 mb-3">User details</h5>
                  <Row>
                    <Col md="6">
                      <InputField
                        type="text"
                        name="name"
                        label="Name"
                        placeholder="User full name"
                        value={values.name || ''}
                        onChange={handleChange}
                        errorMsg={errors.name}
                        required
                      />
                    </Col>
                    <Col md="6">
                      <InputField
                        type="text"
                        name="phone"
                        label="Phone number"
                        maxLength={10}
                        value={values.phone || ''}
                        onChange={handleChange}
                        onKeyDown={handleNumbersOnlyKeyDown}
                        errorMsg={errors.phone}
                        required
                      />
                    </Col>
                    <Col md="6">
                      <InputField
                        type="email"
                        name="email"
                        label="Email address"
                        value={values.email || ''}
                        onChange={handleChange}
                        errorMsg={errors.email}
                        required
                      />
                    </Col>
                    <Col md="6">
                      <InputField
                        type="text"
                        name="password"
                        label="Password"
                        value={values.password || ''}
                        onChange={handleChange}
                        errorMsg={errors.password}
                        required
                      />
                    </Col>
                  </Row>
                  <FormSubmit
                    type="submit"
                    variant="primary"
                    btnText="Add User"
                  />
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default UserAdd;