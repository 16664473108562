import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, Table, Form, Button } from 'react-bootstrap';
import axios from 'axios';

import Payment from './Payment';
import { formatDate } from '../utils';
import useCommit from '../utils/useCommit';

const BookingOverview = (props) => {
  const { setIsCommitting, FormSubmit } = useCommit();
  const { data, calculation, discount, bookingDiscount, bookingResponseCallback, calculationModal, bookingCalculationResCallback, ...rest } = props;
  const [paymentData, setPaymentData] = useState(null);
  const [couponMessage, setCouponMessage] = useState({
    status: '',
    message: ''
  });
  const [isCoupon, setIsCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState('');

  useEffect(() => {
    if(calculationModal === false){
      setIsCoupon(false);
      setCouponCode('');
      setCouponMessage({
        status: '',
        message: ''
      });
    }
  }, [calculationModal]);
 
  const paymentCallback = (data) => {
    setPaymentData(data);
    if (paymentData)
    {
      if (data.paymentType !== paymentData.paymentType)
      {
        paymentCalculation(data);
      }
    }else{
      paymentCalculation(data);
    }
  }

  const paymentCalculation = (data) =>{
      
       const formdata = {
         servicePrice: calculation.servicePrice,
         distancePercent: calculation.distancePercent,
         samedayFee: calculation.samedayFee,
         surcharge: calculation.surcharge,
         paymentOption: data.paymentType,
         autoDiscount: calculation.autoDiscount,
         couponDiscount: calculation.couponDiscount,
         bookingDiscount: bookingDiscount,
       }
      axios.post('/api/customers/online-booking/payment-calculation', formdata).then(res => {
        bookingCalculationResCallback({
          status: 'success',
          data: {
            servicePrice: res.data.servicePrice,
            distancePercent: res.data.distancePercent,
            distancekm: calculation.distancekm,
            spercent: calculation.spercent,
            samedayFee: res.data.samedayFee,
            surcharge: res.data.surcharge,
            gst: res.data.gst,
            total: res.data.total,
            bookingOnOff: res.data.bookingOnOff,
            discount: res.data.discount,
            autoDiscount: res.data.autoDiscount,
            couponDiscount: res.data.couponDiscount,
            bookingDiscount: res.data.bookingDiscount,
          }
        });
      }).catch(error => {

      });
  }

  const handleCoupon = () => {
    let serviceName = data.serviceName;
    if (couponCode) {
      setIsCommitting(true);
      const data = {
        serviceName: serviceName,
        coupon: couponCode,
        servicePrice: calculation.servicePrice,
        distancePercent: calculation.distancePercent,
        samedayFee: calculation.samedayFee,
        surcharge: calculation.surcharge,
        discount: bookingDiscount,
        autoDiscount: calculation.autoDiscount,
        couponDiscount: calculation.couponDiscount,
      }
      axios.post('/api/customers/online-booking/coupon-apply', data).then(res => {
        
        if (res.data.status === 'error') {
          setCouponMessage({
            status: res.data.status,
            message: res.data.message
          });
        }else{
          bookingCalculationResCallback({
            status: 'success',
            data:{
              servicePrice: res.data.data.servicePrice,
              distancePercent: res.data.data.distancePercent,
              distancekm: calculation.distancekm,
              spercent: calculation.spercent,
              samedayFee: res.data.data.samedayFee,
              surcharge: res.data.data.surcharge,
              gst: res.data.data.gst,
              total: res.data.data.total,
              bookingOnOff: res.data.data.bookingOnOff,
              discount: res.data.data.discount,
              autoDiscount: res.data.data.autoDiscount,
              couponDiscount: res.data.data.couponDiscount,
              bookingDiscount: res.data.data.bookingDiscount,
            }
          });
          setCouponMessage({
            status: res.data.status,
            message: 'Coupon successfuly applied'
          });
        }
        setIsCommitting(false);
      }).catch(error => {
        setIsCommitting(false);
      });
    }
  }

  const handleCheckout = () => {
    if (data !== null && calculation !== null && paymentData !== null) {
      setIsCommitting(true);
      const { 
        serviceName, 
        bookedDate, 
        bookingTime, 
        isCarParking, 
        parkingSpot, 
        issue,
        notes,
      } = data;
      const {
        distancePercent,
        distancekm,
        servicePrice, 
        autoDiscount,
        couponDiscount,
        discount,
        samedayFee, 
        surcharge, 
        gst, 
        total
      } = calculation;
      const {
        paymentType,
        name,
        number,
        expiry,
        security
      } = paymentData;
      const formdata = {
        service: serviceName, 
        book_date: bookedDate ? formatDate(bookedDate) : formatDate(new Date()), 
        book_time: bookingTime, 
        parking_sts: isCarParking, 
        parking_spot: parkingSpot, 
        platform_type: issue,  
        bk_note: notes, 
        agree: 'Yes', 
        coupon: couponCode,
        distance_km: distancekm, 
        surcharge_percent: distancePercent, 
        surcharge_val: surcharge, 
        pay_amount: total, 
        sdbf_val: samedayFee, 
        service_cost: servicePrice, 
        discount: discount,
        gst: gst,
        onsite_payment_type: '', 
        payment_type: paymentType, 
        card_name: name, 
        card_no: number, 
        cardExpiry: expiry, 
        cvvNumber: security,
        auto_discount: autoDiscount,
        coupon_discount: couponDiscount
      }
      axios.post('/api/customers/online-booking/store', formdata)
      .then(res => {
        bookingResponseCallback(res.data.status);
      })
      .catch(error => console.log(error));
    }
  }

  return (
    <Modal
      {...rest}
      centered
      backdrop="static"
      aria-labelledby="BookingOverView"
      dialogClassName="BookingOverView"
    >
      <Modal.Header closeButton>
        <Modal.Title id="BookingOverView">
          Appointment Overview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive className="BookingOverView-Table">
          <tbody>
            <tr>
              <td>Service - {data.serviceName}</td>
              <td>${calculation && calculation.servicePrice ? calculation.servicePrice : 0}</td>
            </tr>
            {
            calculation && calculation.discount > 0 &&
              <tr>
                <td className="text-success">Discount</td>
                <td className="text-success">-${ parseFloat(calculation.discount).toFixed(2)}</td>
              </tr>
            }
            {
              calculation && calculation.samedayFee > 0 &&
                <tr>
                  <td>Sameday fee</td>
                  <td>${calculation.samedayFee}</td>
                </tr>
            }
            {
              calculation && calculation.surcharge > 0 &&
                <tr>
                  <td>
                    Sameday fee<br />
                    <small className="text-secondary d-block">
                      {calculation.spercent}% surcharge has been added based on {calculation.distancekm} kilometres
                    </small>
                  </td>
                  <td>${calculation.surcharge}</td>
                </tr>
            }
            <tr>
              <td>GST</td>
              <td>${calculation && calculation.gst ? calculation.gst : 0}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>Total payable</th>
              <th>${calculation && calculation.total ? calculation.total : 0}</th>
            </tr>
          </tfoot>
        </Table>
        {
          calculation && calculation.autoDiscount === 0 &&
          <Form.Group controlId="couponCheck" className="mb-3">
            <Form.Check
              custom
              type="checkbox"
              label="Apply promotional code/voucher"
              name="couponCheck"
              checked={isCoupon === undefined ? false : isCoupon}
              onChange={(event) => setIsCoupon(event.target.checked)}
            />
          </Form.Group>
        }
        {
          isCoupon && 
            <Row>
              <Col md={8}>
                <Form.Group controlId="couponCode">
                  <Form.Control
                    type="text"
                    placeholder="Please add coupon code"
                    value={couponCode}
                    name = "couponCode"
                    className="text-uppercase"
                    onChange={(event) => setCouponCode(event.target.value)}
                  />
                </Form.Group>
                {
                  couponMessage.status &&
                  <div className={`mb-3 ${couponMessage.status === 'success' ? 'text-primary' : 'text-danger'}`}>
                    { couponMessage.message }
                  </div>
                }
              </Col>
              <Col md={4}>
                { 
                  calculation.couponDiscount > 0 ?
                  <Button
                    block
                    variant="primary"
                    disabled={ true }
                  >Apply</Button> 
                  :
                  <FormSubmit
                    block
                    variant="primary"
                    onClick={() => handleCoupon()}
                    btnText = "Apply"
                  />
                }
                
              </Col>
            </Row>
        }
        <Payment 
          dataCallback={paymentCallback} 
          checkoutHandler={() => handleCheckout()}
        />
      </Modal.Body>
    </Modal>
  );
}
 
export default BookingOverview;