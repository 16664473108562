import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Table, Modal, Button, Dropdown } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { useAuth } from '../context/auth';
import Layout from '../components/Layout';
import { InputField } from '../components/common';
import { handleNumbersOnlyKeyDown } from '../utils';
import useForm from '../components/form/useForm';
import validate from '../components/form/BankValidation';
import useCommit from '../utils/useCommit';

const ManageBank = () => {
  const { auth } = useAuth();
  const permissions = auth.permission;
  const [isLoaded, setIsLoaded] = useState(false);
  const [banks, setBanks] = useState([]);
  const [edit, setEdit] = useState({
    isActive: false,
    bankId: null
  });
  const [modalShow, setModalShow] = useState(false);
  const { setMessage, AlertMessage, setIsCommitting, FormSubmit } = useCommit();

  const fetchData = () => {
    axios.get('/api/partners/bank-list')
      .then(res => {
        setBanks(res.data);
        setIsLoaded(true);
      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    fetchData();
  }, []);

  const addPayment = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      setIsCommitting(true);
      if (edit.isActive && edit.bankId !== null) {
        const { bankName, name, number, bsb } = values;
        axios.post(`/api/partners/bank-info/edit/${edit.bankId}`, {
            bank_name: bankName, 
            ac_name: name, 
            ac_number: number, 
            bsb
          })
          .then(res => {
            handleFormReset();
            setIsCommitting(false);
            setModalShow(false);
            fetchData();
            setMessage({
              type: 'success',
              text: 'Bank information has been updated!'
            });
          })
          .catch(error => console.log(error));
      } else {
        const { bankName, name, number, bsb } = values;
        axios.post('/api/partners/bank-info/add', {
            bank_name: bankName, 
            ac_name: name, 
            ac_number: number, 
            bsb
          })
          .then(res => {
            handleFormReset();
            setIsCommitting(false);
            setModalShow(false);
            fetchData();
            setMessage({
              type: 'success',
              text: 'A bank has been added!'
            });
          })
          .catch(error => console.log(error));
      }
    }
  }

  const { 
    values, 
    errors, 
    setValues,
    handleChange,
    handleSubmit, 
    handleFormReset
  } = useForm(addPayment, validate);

  const handleEdit = (bankId) => {
    const target = banks.find(item => item.id === bankId);
    if (target) {
      const { bank_name, ac_name, ac_number, bsb } = target;
      setModalShow(true);
      setValues({
        bankName: bank_name,
        name: ac_name,
        number: ac_number,
        bsb
      });
      setEdit({
        isActive: true,
        bankId
      });
    }
  }
  
  const handleDelete = (bankId) => {
    axios.get(`/api/partners/bank-info/delete/${bankId}`)
      .then(res => {
        setMessage({
          type: 'danger',
          text: 'Bank has been deleted!'
        });
        fetchData();
      })
      .catch(error => console.log(error));
  }

  return (
    <Layout dataIsLoaded={isLoaded}>
      <div className="Dashboard-ContentBody__Header">
        <h1 className="Dashboard-ContentBody__Header--Title">
          Billing Settings
        </h1>
      </div>
      <div className="Dashboard-ContentBody__TableData">
        <Row>
          <Col lg="8">
            <AlertMessage />
            <Card className="shadow-sm mb-4">
              <Card.Body>
                <div className="mb-3">
                  { permissions.find(item => item === "partner-billing-settings-add") &&
                  <Button
                    variant="primary"
                    onClick={() => setModalShow(true)}
                  >Add Method</Button>
                  }
                </div>
                <Table>
                  <tbody>
                    {
                      banks.map(bank => (
                        <tr key={bank.id}>
                          <td style={{ verticalAlign: 'middle' }}>
                            <strong>{bank.bank_name}</strong> ending in {(bank.ac_number).slice(-4)}
                            <p className="text-secondary mb-0">{bank.ac_name}</p>
                          </td>
                          <td className="tableNav">
                            { permissions.find(item => item === "partner-billing-settings-delete") &&
                                <Dropdown>
                                  <Dropdown.Toggle id="table-more">
                                    <MoreVertical size="18" />
                                  </Dropdown.Toggle>
        
                                  <Dropdown.Menu className="shadow border-0" alignRight>
                                    <Link 
                                      to="#"
                                      className="dropdown-item"
                                      onClick={() => handleEdit(bank.id)}
                                    >Edit</Link>
                                    <Link 
                                      to="#"
                                      className="dropdown-item"
                                      onClick={() => handleDelete(bank.id)}
                                    >Delete</Link>
                                  </Dropdown.Menu>
                                </Dropdown>
                            }
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                <Modal
                  centered
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  aria-labelledby="AddMethod"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="AddMethod">
                      Add a bank
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form onSubmit={handleSubmit} noValidate>
                      <InputField
                        type="text"
                        name="bankName"
                        label="Bank name"
                        value={values.bankName || ''}
                        onChange={handleChange}
                        errorMsg={errors.bankName}
                        required
                      />
                      <InputField
                        type="text"
                        name="name"
                        label="Account name"
                        value={values.name || ''}
                        onChange={handleChange}
                        errorMsg={errors.name}
                        required
                      />
                      <InputField
                        type="text"
                        name="number"
                        label="Account number"
                        value={values.number || ''}
                        onChange={handleChange}
                        onKeyDown={handleNumbersOnlyKeyDown}
                        errorMsg={errors.number}
                        required
                      />
                      <InputField
                        type="text"
                        name="bsb"
                        label="BSB"
                        value={values.bsb || ''}
                        onChange={handleChange}
                        onKeyDown={handleNumbersOnlyKeyDown}
                        errorMsg={errors.bsb}
                        required
                      />
                      <FormSubmit
                        type="submit"
                        variant="primary"
                        btnText={edit.isActive ? 'Save Changes' : 'Add Bank'}
                      />
                    </Form>
                  </Modal.Body>
                </Modal>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default ManageBank;