export default function validate(values, required) {
  let errors = {};

  if (required.currentPassword && !values.currentPassword) {
    errors.currentPassword = 'Current password is required';
  }

  if (required.password) {
    if (!values.password) {
      errors.password = 'New password is required';
    } else if (values.password.length < 8) {
      errors.password = 'Password must be 8 or more characters';
    }
  }

  if (required.confirmPassword && !values.confirmPassword) {
    errors.confirmPassword = 'Re-type new password is required';
  }

  return errors;
}