import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Modal, Form, Button, Dropdown } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Layout from '../components/Layout';
import { InputField, InputSelect } from '../components/common';
import { stateOptions } from '../staticData';
import useForm from '../components/form/useForm';
import validate from '../components/form/AddressValidation';
import useCommit from '../utils/useCommit';
import DeletePopup from '../components/DeletePopup';

const Addresses = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [title, setTitle] = useState('Add a address');
  const [edit, setEdit] = useState({
    isActive: false,
    addressId: null
  });
  const [deleteItem, setDeleteItem] = useState({
    isActive: false,
    addressId: null
  });
  const [modalShow, setModalShow] = useState(false);
  const { setMessage, AlertMessage, setModalMessage, AlertModalMessage, setIsCommitting, FormSubmit } = useCommit();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get('/api/customers/address/list').then(res => {
      setAddresses(res.data);
      setIsLoaded(true);
    }).catch(error => {

    });
  }
  const addUpdateAddress = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      setIsCommitting(true);
      const data = {
        id: values.id,
        street: values.address1,
        city: values.city,
        state: values.state,
        post_code: values.zipcode,
      }
      if (edit.isActive && edit.addressId !== null) {
        axios.post('/api/customers/address/edit', data).then(res => {
          fetchData();
          setMessage({
            type: 'success',
            text: res.data.message
          });
          setModalShow(false);
          setValues({
            id: '',
            address1: '',
            city: '',
            state: '',
            zipcode: ''
          });
          setIsCommitting(false);
        }).catch(error => {

        });
      } else {
        axios.post('/api/customers/address/add', data).then(res => {
          if (res.data.status === 'success') {
            fetchData();
            setMessage({
              type: 'success',
              text: res.data.message
            });
            setModalShow(false);
            setValues({
              id: '',
              address1: '',
              city: '',
              state: '',
              zipcode: ''
            });
          } else {
            setModalMessage({
              type: 'danger',
              text: res.data.message
            });
          }
          setIsCommitting(false);
        }).catch(error =>{

        });
      }
    }
  }

  const addAddress = () => {
    setIsCommitting(false);
    setEdit({
      isActive: false,
      addressId: null
    });
    setTitle('Add a address');
    setModalShow(true);
    setValues({
      id: '',
      address1: '',
      city: '',
      state: '',
      zipcode: ''
    });
  }
  const handleEdit = (addressId) => {
    const target = addresses.find(item => item.id === addressId);
    if (target) {
      setIsCommitting(false);
      setEdit({
        isActive: true,
        addressId: addressId
      });
      setTitle('Edit address');
      setValues({
        id: target.id,
        address1: target.street,
        city: target.city,
        state: target.state,
        zipcode: target.post_code
      });
      setModalShow(true)
    }
  }
  
  const handleDelete = (addressId) => {
     setIsCommitting(true);
    axios.post('/api/customers/address/delete/' + addressId).then(res => {
      fetchData();
      setDeleteItem({
        id: null,
        isActive: false
      });
      setMessage({
        type: 'success',
        text: res.data.message
      });
      setIsCommitting(false);
    }).catch(error => {

    });
  }

  const { 
    values, 
    errors, 
    setValues,
    handleChange,
    handleSelectChange,
    handleSubmit, 
    handleFormReset
  } = useForm(addUpdateAddress, validate);
  return (
    <Layout dataIsLoaded={isLoaded}>
      <div className="Dashboard-ContentBody__Header">
        <h1 className="Dashboard-ContentBody__Header--Title">
          Address Book
        </h1>
      </div>
      <div className="Dashboard-ContentBody__TableData">
        <Row>
          <Col lg="8">
            <AlertMessage />
            <Card className="shadow-sm mb-4">
              <Card.Body>
                <div className="mb-3">
                  <Button
                    variant="primary"
                    onClick={ addAddress }
                  >Add Address</Button>
                </div>
                <Table>
                  <tbody>
                    {
                      addresses.map(addr => (
                      <tr key={ addr.id }>
                        <td>
                          <p className="mb-0">
                            { addr.address }
                          </p>
                        </td>
                        <td className="tableNav">
                          <Dropdown>
                            <Dropdown.Toggle id="table-more">
                              <MoreVertical size="18" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="shadow border-0" alignRight>
                              <Link 
                                to="#"
                                className="dropdown-item"
                                onClick={() => handleEdit(addr.id)}
                              >Edit</Link>
                              <Link 
                                to="#"
                                className="dropdown-item"
                                onClick={() => setDeleteItem({isActive: true, addressId: addr.id})}
                              >Delete</Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                      ))
                    }
                  </tbody>
                </Table>
                <Modal
                  centered
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  aria-labelledby="AddMethod"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="AddMethod">
                      { title }
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <AlertModalMessage />
                    <Form onSubmit={handleSubmit} noValidate>
                      <Row>                    
                        <Col md="12">
                          <InputField
                            type="text"
                            name="address1"
                            label="Street"
                            placeholder="Street address, P.O. Box"
                            value={values.address1 || ''}
                            onChange={handleChange}
                            errorMsg={errors.address1}
                            required
                          />
                        </Col>
                        <Col md="12">
                          <InputField
                            type="text"
                            name="city"
                            label="City"
                            value={values.city || ''}
                            onChange={handleChange}
                            errorMsg={errors.city}
                            required
                          />
                        </Col>
                        <Col md="6">
                          <InputSelect
                            name="state"
                            label="State"
                            placeholder="Make a selection"
                            options={stateOptions}
                            value={stateOptions.find(item => item.value === values.state) || ''}
                            onChange={(event) => handleSelectChange(event.value, 'state')}
                            errorMsg={errors.state}
                            required={true}
                          />
                        </Col>
                        <Col md="6">
                          <InputField
                            type="text"
                            name="zipcode"
                            label="Zip code"
                            value={values.zipcode || ''}
                            onChange={handleChange}
                            errorMsg={errors.zipcode}
                            required
                          />
                        </Col>
                      </Row>
                      <FormSubmit
                        type="submit"
                        variant="primary"
                        btnText={edit.isActive ? 'Save Changes' : 'Add Address'}
                      />
                    </Form>
                  </Modal.Body>
                </Modal>
              </Card.Body>
            </Card>
            <DeletePopup
              clickHandler={ () => handleDelete(deleteItem.addressId) }
              show={deleteItem.isActive}
              onHide={() => setDeleteItem({ id: null, isActive: false })}
            />
          </Col>
        </Row>
      </div>
    </Layout>
  );
}
 
export default Addresses;