import React from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { Trash2 } from 'react-feather';

const DeletePopup = (props) => {
  const {clickHandler, ...rest} = props;
  return (
    <Modal
      {...rest}
      size="sm"
      centered
      aria-labelledby="DeleteConfirmation"
    >
      <Modal.Body>
        <Row className="juntify-content-center align-items-center">
          <Col xs={2}>
            <Trash2 color="#EA3A60" size={32} />
          </Col>
          <Col xs={10}>
            <p className="text-secondary mb-0">Are you sure you want to delete this item?</p>
          </Col>
          <Col xs={6} className="pr-2">
            <Button
              block
              variant="light"
              className="mt-4"
              onClick={props.onHide}
            >Cancel</Button>
          </Col>
          <Col xs={6} className="pl-2">
            <Button
              block
              variant="primary"
              className="mt-4"
              onClick={clickHandler}
            >Confirm</Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
 
export default DeletePopup;