import React, { Fragment, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { PaymentContext } from '../../context/payment';
import CardMethod from './CardMethod';
import CashMethod from './CashMethod';

const Payment = ({ dataCallback, checkoutHandler }) => {
  const [paymentOption, setPaymentOption] = useState('credit card');
  const [cardData, setCardData] = useState(null);
  const [settings, setSettings] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState({
    isActive: false,
    error: false
  });

  useEffect(() => {
    if (cardData !== null) {
      const { number, name, expiry, security } = cardData;
      dataCallback({
        paymentType: paymentOption,
        number,
        name,
        expiry,
        security
      });
      axios.get('/api/customers/settings').then(res =>{
        setSettings(res.data);
      }).catch(error =>{

      });
    }
    
    // eslint-disable-next-line
  }, [paymentOption, cardData]);

  return (
    <PaymentContext.Provider value={{
      paymentOption,
      cardData,
      acceptTerms,
      setAcceptTerms,
      setCardData,
      checkoutHandler
    }}>
      <div className="MakePayment">
        <Form.Group controlId="acceptTerms" className="mb-3">
          <Form.Check
            custom
            type="checkbox"
            label={<Fragment>By clicking here you agree to the <Link to="/terms-and-conditions" target="_blank">terms and conditions</Link>.</Fragment>}
            name="acceptTerms"
            checked={acceptTerms.isActive === undefined ? false : acceptTerms.isActive}
            onChange={(event) => setAcceptTerms({ isActive: event.target.checked, error: false })}
            isInvalid={acceptTerms.error}
          />
        </Form.Group>
        <Form.Group controlId="paymentOption" className="mb-3">
          <Form.Check
            custom
            inline
            type="radio"
            label="Credit or Debit Card"
            id="paymentOptionCard"
            name="paymentOption"
            checked={paymentOption === 'credit card'}
            onChange={() => setPaymentOption('credit card')}
          />
          {
            settings && settings.onsite_pay_switch === 'on' &&
            <Form.Check
              custom
              inline
              type="radio"
              label="Onsite"
              id="paymentOptionCash"
              name="paymentOption"
              checked={paymentOption === 'onsite'}
              onChange={() => setPaymentOption('onsite')}
            />
          }
        </Form.Group>
        { paymentOption === 'credit card' && <CardMethod /> }
        { paymentOption === 'onsite' && <CashMethod settings={settings} /> }
      </div>
    </PaymentContext.Provider>
  );
}
 
export default Payment;