export const bookingOptions = [
  { value: 'all', label: 'All', selected: true },
  { value: 'assigned', label: 'Assigned' },
  { value: 'awaiting', label: 'Awaiting' }
];
export const appointmentOptions = [
  { value: 'all', label: 'All', selected: true },
  { value: 'open', label: 'Open' },
  { value: 'hold', label: 'Hold' },
  { value: 'closed', label: 'Closed' },
  { value: 'reopen', label: 'Reopen' },
  { value: 'dispute', label: 'Dispute' },
  { value: 'delivered', label: 'Delivered' },
  { value: 'reassigned', label: 'Reassigned' },
  { value: 'rescheduled', label: 'Rescheduled' }
];

export const stateOptions = [
  { value: 'NSW', label: 'New South Wales' },
  { value: 'QLD', label: 'Queensland' },
  { value: 'SA', label: 'South Australia' },
  { value: 'TAS', label: 'Tasmania' },
  { value: 'VIC', label: 'Victoria' },
  { value: 'WA', label: 'Western Australia' },
  { value: 'ACT', label: 'Australian Capital Territory' },
  { value: 'NT', label: 'Northern Territory' }
];

export const priorityOptions = [
  { value: 'p1', label: 'Top level - P1' },
  { value: 'p2', label: 'Mid level - P2' },
  { value: 'p3', label: 'Low level - P3' }
];

export const bookingUserOptions = [
  { value: 'Business', label: 'Business' },
  { value: 'Home', label: 'Home' }
];

export const serviceNameOptions = [
  { value: 1, label: 'Computer Install' },
  { value: 2, label: 'General Service' }
];

export const serviceIssueOptions = [
  { value: 'MAC', label: 'MAC' },
  { value: 'Windows', label: 'Windows' },
  { value: 'Smart Phone', label: 'Smart Phone' },
  { value: 'Printer', label: 'Printer' },
  { value: 'Router', label: 'Router' },
  { value: 'Internet', label: 'Internet' },
  { value: 'Other', label: 'Other' }
];

export const userRoleOptions = [
  { value: 1, label: 'Owner' },
  { value: 2, label: 'Manager' },
  { value: 3, label: 'Editor' }
];

export const statusOptions = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Deactivate' }
];

export const searchStatusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'deactivate', label: 'Deactivate' }
];

export const parkingSpotOptions = [
  { value: 'Street Paid', label: 'Street Paid' },
  { value: 'Street Unpaid', label: 'Street Unpaid' },
  { value: 'Driveway', label: 'Driveway' }
];