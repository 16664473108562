export default function validate(values, required) {
  let errors = {};

  if (required.role && !values.role) {
    errors.role = 'Choose a role';
  }

  if (required.status && !values.status) {
    errors.status = 'Choose a status';
  }

  if (required.name && !values.name) {
    errors.name = 'Name is required';
  }

  if (required.phone && !values.phone) {
    errors.phone = 'Phone number is required';
  }

  if (required.email) {
    if (!values.email) {
      errors.email = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is not valid';
    }
  }

  if (required.password) {
    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values.password.length < 8) {
      errors.password = 'Password must be 8 or more characters';
    }
  }

  return errors;
}