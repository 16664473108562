import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Table, Dropdown, Modal } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { InputField } from '../components/common';
import Layout from '../components/Layout';
import TablePagination from '../utils/TablePagination';
import useForm from '../components/form/useForm';
import validate from '../components/form/CcValidation';
import CardIcons from '../components/Payment/CardIcons';
import SecurityMore from '../components/Payment/SecurityMore';
import useCommit from '../utils/useCommit';
import { handleNumbersOnlyKeyDown, handleCardExpiryKeyUp, ccFormat, ccType, removeWhiteSpace } from '../utils';

const Licenses = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [licenses, setLicenses] = useState([]);
  const [date, setDate] = useState('');
  const [page, setPage] = useState({
    type: 'list',
    currentPage: 1,
    totalPages: 1
  });
  const [view, setView] = useState({
    data: null,
    isActive: false
  });
  const [payment, setPayment] = useState({
    data: null,
    isActive: false
  });

  const { setIsCommitting, FormSubmit, scrollToTop, setMessage, AlertMessage, setModalMessage, AlertModalMessage } = useCommit();

  const makePayment = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      setIsCommitting(true);
      const data = {
        id: payment.data.id,
        cardNumber: values.cardNumber,
        cardholderName: values.cardholderName,
        expiryDate: values.expiryDate,
        cvv: values.securityCode,
      }
      axios.post('/api/customers/license/renewal/payment', data).then(res => {
        if (res.data.status === "success")
        {
          setIsLoader(false);
          fetchData(1);
          setPayment({
            data: null,
            isActive: false
          });
          setMessage({
            type: 'success',
            text: res.data.message,
          });
          setValues({
            cardNumber: '',
            cardholderName: '',
            expiryDate: '',
            securityCode: '',
          });
          setIsCommitting(false);
          scrollToTop();
        }else{
          setModalMessage({
            type: 'danger',
            text: res.data.message,
          });
          setIsCommitting(false);
        }
        
      }).catch(error => {
        setIsCommitting(false);
      });
    }
  }
  useEffect(() => {
    fetchData(1);
  }, []);

  const fetchData = (pageNumber) => {
    axios.get('/api/customers/licenses', {
        params: { page: pageNumber }
      })
      .then(res => {
        setLicenses(res.data.licenses.data);
        setDate(res.data.today);
        setIsLoaded(true);
        setPage({
          type: 'list',
          currentPage: res.data.licenses.current_page,
          totalPages: res.data.licenses.last_page,
          total: res.data.licenses.total,
          per_page: res.data.licenses.per_page,
        });
      })
      .catch(error => console.log(error));
  }
  const handlePagination = (pageNumber) => {
      fetchData(pageNumber);
  }

  const handleClose = () => {
    setView({
      data: null,
      isActive: false
    });
  }
  const handlePmodalClose = () => {
    setPayment({
      data: null,
      isActive: false
    });
    setValues({
      cardNumber: '',
      cardholderName: '',
      expiryDate: '',
      securityCode: '',
    });
    setIsCommitting(false);
    setIsLoader(false);
  }
  const openViewModal = (id) => {
    var dt = licenses.find(license => license.id === id);
    setView({
      data: licenses.find(license => license.id === id),
      isActive: true
    })
  }
  const openPaymentModal = (id) => {
    setIsLoader(false);
    setPayment({
      data: licenses.find(license => license.id === id),
      isActive: true
    });
    setIsLoader(true);
  }
  const {
    values,
    errors,
    handleChange,
    setValues,
    handleSubmit
  } = useForm(makePayment, validate);

  return (
    <Layout dataIsLoaded={isLoaded}>
      <div className="Dashboard-ContentBody__Header">
        <Row>
          <Col md="12">
            <AlertMessage />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <h1 className="Dashboard-ContentBody__Header--Title">
              Licenses
            </h1>
          </Col>
        </Row>
      </div>
      <div className="Dashboard-ContentBody__TableData">
        <Row>
          <Col>
            <Card className="shadow-sm border-0">
              <Card.Body>
                <Table responsive>
                  <colgroup>
                    <col width="auto" />
                    <col width="20%" />
                    <col width="20%" />
                    <col width="15%" />
                    <col width="15%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>License type</th>
                      <th>LC Year</th>
                      <th>Expiry</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      licenses.map(license => (
                        <tr key={license.id} style={{ background:  license.expire_dt < date ? "#ffbcbc" : ""  }}>
                          <td>
                            <strong>{license.products.name}</strong>
                              <small className="text-secondary"><strong>{license.quotation_id}</strong></small>
                          </td>
                          <td>{license.license_type}</td>
                          <td>{license.years_of_subs}</td>
                          <td><Moment date={license.expire_dt} format="DD MMM, YYYY" /></td>
                          <td className="tableNav">
                            <Dropdown>
                              <Dropdown.Toggle id="table-more">
                                <MoreVertical size="18" />
                              </Dropdown.Toggle>
    
                              <Dropdown.Menu className="shadow border-0" alignRight>
                                  <Link 
                                    to="#"
                                    className="dropdown-item"
                                    onClick={ () => openViewModal(license.id) }
                                  >View</Link>
                                  {
                                  license.expire_dt < date &&
                                    <Link 
                                      to="#"
                                      className="dropdown-item"
                                      onClick={ () => openPaymentModal(license.id) }
                                    >Renewal Payment</Link>
                                  }
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    }
                    
                  </tbody>
                </Table>
                <Modal
                  show={view.isActive}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>License details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Table responsive>
                      <colgroup>
                        <col width="25%" />
                        <col width="auto" />
                      </colgroup>
                        {
                          view.data &&
                            <tbody>
                              <tr>
                                <th>Order ID</th>
                                <td>#{ view.data.quotation_id }</td>
                              </tr>
                              {
                                view.data.user &&
                                <tr>
                                  <th>Served by</th>
                                  <td>{ view.data.user.name }</td>
                                </tr>
                              }
                              <tr>
                                <th>Years of subs.</th>
                                <td>{ view.data.years_of_subs }</td>
                              </tr>
                              <tr>
                                <th>License From</th>
                                <td><Moment date={view.data.start_dt} format="DD MMM, YYYY" /></td>
                              </tr>
                              <tr>
                                <th>Expire Date</th>
                                <td><Moment date={view.data.expire_dt} format="DD MMM, YYYY" /></td>
                              </tr>
                              <tr>
                                <th>Product name</th>
                                <td>{ view.data.products.name }</td>
                              </tr>
                              <tr>
                                <th>Quantity</th>
                                <td>{ view.data.quantity }</td>
                              </tr>
                              <tr>
                                <th>Price</th>
                                <td>{ view.data.price }</td>
                              </tr>
                              <tr>
                                <th>Subtotal</th>
                                <td>{ view.data.subtotal }</td>
                              </tr>
                              <tr>
                                <th>GST</th>
                                <td>{ view.data.gst }</td>
                              </tr>
                              {
                                view.data.amex_charge > 0 &&
                                <tr>
                                  <th>Amex Charge</th>
                                  <td>{ view.data.amex_charge }</td>
                                </tr>
                              }
                              <tr>
                                <th>Total price</th>
                                <td>{ (parseFloat(view.data.total)+ parseFloat(view.data.amex_charge)).toFixed(2) }</td>
                              </tr>
                            </tbody>
                      }
                    </Table>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={payment.isActive}
                  onHide={handlePmodalClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Renew</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <AlertModalMessage />
                    <div className="CardMethod">
                      <Form onSubmit={handleSubmit} noValidate>
                        <CardIcons />
                        <div className="position-relative">
                          <div className="CardMethod-FieldIcon">
                            <span className={`CardMethod-FieldIcon__${ccType(removeWhiteSpace(values.cardNumber))}`}></span>
                          </div>
                          <InputField
                            type="tel"
                            name="cardNumber"
                            label="Card number"
                            maxLength="19"
                            value={ccFormat(values.cardNumber) || ''}
                            onChange={handleChange}
                            onKeyDown={handleNumbersOnlyKeyDown}
                            errorMsg={errors.cardNumber}
                            required
                            autoFocus
                          />
                        </div>
                        <InputField
                          type="text"
                          name="cardholderName"
                          label="Cardholder name"
                          value={values.cardholderName || ''}
                          onChange={handleChange}
                          errorMsg={errors.cardholderName}
                          required
                        />
                        <Row>
                          <Col md="6">
                            <InputField
                              type="tel"
                              name="expiryDate"
                              label="Expires on"
                              placeholder="MM/YY"
                              maxLength="5"
                              value={values.expiryDate || ''}
                              onChange={handleChange}
                              onKeyDown={handleNumbersOnlyKeyDown}
                              onKeyUp={handleCardExpiryKeyUp}
                              errorMsg={errors.expiryDate}
                              required
                            />
                          </Col>
                          <Col md="6">
                            <div className="position-relative">
                              <SecurityMore />
                              <InputField
                                type="tel"
                                name="securityCode"
                                label="Security code"
                                maxLength="4"
                                value={values.securityCode || ''}
                                onChange={handleChange}
                                onKeyDown={handleNumbersOnlyKeyDown}
                                errorMsg={errors.securityCode}
                                required
                              />
                            </div>
                          </Col>
                        </Row>
                        {
                          isLoader &&
                          <FormSubmit
                            block
                            size="lg"
                            type="submit"
                            variant="primary"
                            btnText={ "PAY ( $"+payment.data.total+" )" }
                          />
                        }
                      </Form>
                    </div>
                  </Modal.Body>
                </Modal>
                { 
                  page.total > page.per_page &&
                  <TablePagination 
                    currentPage={parseInt(page.currentPage)}
                    totalPages={parseInt(page.totalPages)}
                    clickHandler={handlePagination}
                  />
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}
 
export default Licenses;