import React from 'react';
import { Modal } from 'react-bootstrap';

const PopupVideo = (props) => {
  const {videoUrl, ...rest} = props
  return (
    <Modal
      {...rest}
      size="lg"
      centered
      backdrop="static"
      keyboard={false}
      dialogClassName="VideoPopup"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <iframe 
          width="100%" 
          height="448" 
          src={ videoUrl } 
          frameBorder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen 
        />
      </Modal.Body>
    </Modal>
  );
}
 
export default PopupVideo;