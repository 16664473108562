export default function validate(values, required) {
  let errors = {};

  if (required.refName && !values.refName) {
    errors.refName = 'Name is required';
  }

  if (required.refEmail) {
    if (!values.refEmail) {
      errors.refEmail = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(values.refEmail)) {
      errors.refEmail = 'Email address is not valid';
    }
  }

  return errors;
}