import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Badge, Dropdown, Form } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';
import Moment from 'react-moment';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Layout from '../components/Layout';
import useCommit from '../utils/useCommit';
import TablePagination from '../utils/TablePagination';
import { appointmentOptions } from '../staticData';
import Reviewing from '../components/Reviewing';
import AppointmentView from '../components/AppointmentView';
import BookingView from '../components/BookingView';

const Appointments = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [contact, setContact] = useState({
    keywords: '',
    status: 'all'
  });
  const [appointments, setAppointments] = useState([]);
    const [bookings, setBookings] = useState([]);
  const [page, setPage] = useState({
    type: 'list',
    currentPage: 1,
    totalPages: 1
  });
  const [reviewModal, setReviewModal] = useState({
    isActive: false,
    appId: null
  });
  const [viewModal, setViewModal] = useState({
    isActive: false,
    appId: null
  });
  const [viewBookingModal, setViewBookingModal] = useState({
    isActive: false,
    bookingId: null
  });
  const { setIsCommitting, FormSubmit } = useCommit();

  const fetchData = (pageNumber) => {
    axios.get('/api/customers/appointment-list', {
        params: { page: pageNumber }
      })
      .then(res => {
        setBookings(res.data.bookings);
        setAppointments(res.data.appointments.data);
        setIsLoaded(true);
        setContact({
          keywords: '',
          status: 'all'
        });
        setPage({
          type: 'list',
          currentPage: res.data.appointments.current_page,
          totalPages: res.data.appointments.last_page,
          total: res.data.appointments.total,
          per_page: res.data.appointments.per_page,
        });
      })
      .catch(error => console.log(error));
  }

  const fetchSearchData = (pageNumber) => {
    const { keywords, status } = contact;
    axios.post('/api/customers/appointment-list/search', {
        keywords,
        status
      }, {
        params: { page: pageNumber }
      })
      .then(res => {
        setBookings(res.data.bookings);
        setAppointments(res.data.appointments.data);
        setIsCommitting(false);
        setPage({
          type: 'search',
          currentPage: res.data.current_page,
          totalPages: res.data.last_page,
          total: res.data.total,
          per_page: res.data.per_page,
        });

      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  const handleFilter = (event) => {
    event.preventDefault();
    setIsCommitting(true);
    fetchSearchData(1);
  }

  const handlePagination = (pageNumber) => {
    if (page.type === 'list') {
      fetchData(pageNumber);
    } else {
      fetchSearchData(pageNumber);
    }
  }
  function statusColor($status) {
    if ($status === 'open')
      return "primary";
    else if($status === 'rescheduled')
      return "secondary";
    else if($status === 'cancelled')
      return "danger";
    else if ($status === 'reassigned')
      return "info";
    else if ($status === 'hold')
      return "warning";
    else if ($status === 'dispute')
      return "info";
    else if ($status === 'delivered')
      return "success";
    else if ($status === 'reopen')
      return "light";
    else
      return "dark";
    
  }

  return (
    <Layout dataIsLoaded={isLoaded}>
      <div className="Dashboard-ContentBody__Header">
        <Row>
          <Col md="6">
            <h1 className="Dashboard-ContentBody__Header--Title">
              Appointments
            </h1>
          </Col>
        </Row>
      </div>
      <div className="Dashboard-ContentBody__TableData">
        <Row>
          <Col>
            <Card className="shadow-sm border-0">
              <Card.Header className="bg-white pt-4">
                <Form onSubmit={handleFilter}>
                  <Row>
                    <Col md="5">
                      <Form.Group controlId="formSearch">
                        <Form.Label className="sr-only">Search</Form.Label>
                        <Form.Control 
                          type="text" 
                          placeholder="Search for a appointment" 
                          value={contact.keywords}
                          onChange={(event) => setContact({ ...contact, keywords: event.target.value })}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group controlId="formStatus">
                        <Form.Label className="sr-only">Status</Form.Label>
                        <Select 
                          instanceId="formStatus"
                          classNamePrefix="NextSelect"
                          placeholder="Select a status"
                          options={appointmentOptions}
                          value={appointmentOptions.find(item => item.value === contact.status)}
                          onChange={(event) => setContact({ ...contact, status: event.value })}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <FormSubmit
                        type="submit"
                        variant="secondary"
                        btnText="Search"
                      />
                    </Col>
                  </Row>
                </Form>
              </Card.Header>
              <Card.Body>
                <Table responsive>
                  <colgroup>
                    <col width="15%" />
                    <col width="15%" />
                    <col width="auto" />
                    <col width="28%" />
                    <col width="14%" />
                    <col width="50px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Ref.</th>
                      <th>Type</th>
                      <th>Service</th>
                      <th>Appointment Date</th>
                      <th>Status</th>
                      <th className="tableNav">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      bookings.map(booking => (
                        <tr key={booking.id}>
                          <td>
                            {booking.booking_id}
                          </td>
                          <td>
                            {booking.booking_type}
                          </td>
                          <td>
                            <strong>{booking.service}</strong>
                          </td>
                          <td>
                            <Moment date={booking.book_date} format="ddd, DD MMM YYYY" />, <Moment date={booking.book_date+' '+booking.book_time} format="hh:mm A" />
                          </td>
                          <td>
                            <Badge
                              variant={booking.booking_status === 'awaiting' ? 'warning' : 'success'}
                            ><span className="text-capitalize font-weight-normal">{booking.booking_status}</span></Badge>
                          </td>
                          <td className="tableNav">
                            <Dropdown>
                              <Dropdown.Toggle id="table-more">
                                <MoreVertical size="18" />
                              </Dropdown.Toggle>
    
                              <Dropdown.Menu className="shadow border-0" alignRight>
                                  <Link 
                                    to="#"
                                    className="dropdown-item"
                                    onClick={() => setViewBookingModal({ isActive: true, bookingId: booking.booking_id })}
                                  >View</Link>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    }
                    {
                      appointments.map(appointment => (
                        <tr key={appointment.id}>
                          <td>
                              {appointment.booking_id}
                          </td>
                          <td>
                            {appointment.app_type}
                          </td>
                          <td>
                            <strong>{appointment.serv_name}</strong>
                          </td>
                          <td>
                            <Moment date={appointment.app_date} format="ddd, DD MMM YYYY" />, <Moment date={appointment.app_date+' '+appointment.app_time} format="hh:mm A" />
                          </td>
                          <td>
                            <Badge
                              variant={ statusColor(appointment.app_status) }
                            ><span className="text-capitalize font-weight-normal">{appointment.app_status}</span></Badge>
                          </td>
                          <td className="tableNav">
                            <Dropdown>
                              <Dropdown.Toggle id="table-more">
                                <MoreVertical size="18" />
                              </Dropdown.Toggle>
    
                              <Dropdown.Menu className="shadow border-0" alignRight>
                                  <Link 
                                    to="#"
                                    className="dropdown-item"
                                    onClick={() => setViewModal({ isActive: true, appId: appointment.id })}
                                  >View</Link>
                                  {
                                  appointment.app_status === 'closed' && appointment.is_rating === 0 &&
                                  <Link 
                                    to="#"
                                    className="dropdown-item"
                                    onClick={() => setReviewModal({ isActive: true, appId: appointment.id })}
                                  >Rating</Link>
                                  }
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                { 
                  page.total > page.per_page &&
                  <TablePagination 
                    currentPage={parseInt(page.currentPage)}
                    totalPages={parseInt(page.totalPages)}
                    clickHandler={handlePagination}
                  />
                }
                <Reviewing
                  data={appointments.find(appointment => appointment.id === reviewModal.appId)}
                  show={reviewModal.isActive}
                  onHide={() => setReviewModal({ isActive: false, appId: null })}
                />
                <AppointmentView
                  data={appointments.find(appointment => appointment.id === viewModal.appId)}
                  show={viewModal.isActive}
                  onHide={() => setViewModal({ isActive: false, appId: null })}
                />
                <BookingView
                  data={bookings.find(booking => booking.booking_id === viewBookingModal.bookingId)}
                  show={viewBookingModal.isActive}
                  onHide={() => setViewBookingModal({ isActive: false, bookingId: null })}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default Appointments;