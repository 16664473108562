export default function validate(values, required) {
  let errors = {};

  if (required.type && !values.type) {
    errors.type = 'Choose a business type';
  }

  if (required.serviceName && !values.serviceName) {
    errors.serviceName = 'Choose a service name';
  }

  if (required.bookingTime && !values.bookingTime) {
    errors.bookingTime = 'Choose a booking time';
  }

  if (values.isCarParking === 0 || values.isCarParking === 1) {
  } else {
    errors.isCarParking = 'Choose an parking option';
  }
  
  if (values.isCarParking === 1) {
    if (!values.parkingSpot) {
      errors.parkingSpot = 'Select a parking spot';
    }
  }

  if (required.issue && !values.issue) {
    errors.issue = 'Choose a service issue';
  }

  if (required.notes && !values.notes) {
    errors.notes = 'Notes is required';
  }

  if (required.email) {
    if (!values.email) {
      errors.email = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is not valid';
    }
  }

  if (required.name && !values.name) {
    errors.name = 'Name is required';
  }

  if (required.phone && !values.phone) {
    errors.phone = 'Phone number is required';
  }

  if (values.type === 'Business') {
    if (required.businessName && !values.businessName) {
      errors.businessName = 'Business name is required';
    }
  }

  if (required.address1 && !values.address1) {
    errors.address1 = 'Street address is required';
  }

  if (required.city && !values.city) {
    errors.city = 'City is required';
  }

  if (required.state && !values.state) {
    errors.state = 'Choose a state';
  }

  if (required.zipcode && !values.zipcode) {
    errors.zipcode = 'Zipcode is required';
  }

  return errors;
}