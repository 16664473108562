import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import axios from 'axios';

import Layout from '../components/Layout';
import { InputField, InputSelect } from '../components/common';
import { handleNumbersOnlyKeyDown } from '../utils';
import { statusOptions } from '../staticData';
import useForm from '../components/form/useForm';
import validate from '../components/form/UserRoleValidation';
import useCommit from '../utils/useCommit';

const RoleEdit = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [userRoleOptions, setUserRoleOptions] = useState([]);
  const { scrollToTop, setIsCommitting, FormSubmit, setMessage, AlertMessage } = useCommit();

  const addEdit = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      setIsCommitting(true);
      const { name, phone, email } = values;
      const data = {
        role: values.role.value,
        status: values.status.value,
        name, 
        phone, 
        email
      };
      var id = props.match.params.id;
      axios.post('/api/partners/alias-user/profile-update/' + id, data).then(res => {
        setMessage({
          type: res.data.status,
          text: res.data.message,
        });
        setIsCommitting(false);
        scrollToTop();
      }).catch(error => {
        console.log(error);
      });
    }
  }

  const { 
    values, 
    errors, 
    handleChange, 
    handleSelectChange,
    handleSubmit 
  } = useForm(addEdit, validate);

  useEffect(() => {
    axios.get('/api/partners/role')
      .then(res => {
        setUserRoleOptions(res.data.roleList);
      }).catch(error => {
        console.log(error)
      })
    var id = props.match.params.id;
    axios.get('/api/partners/alias-user/profile-info/' + id).then(res =>{
      const data = res.data;
      if (data !== undefined) {
        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
          values[keys[i]] = data[keys[i]];
        }
        setIsLoaded(true);
      }
    }).catch(error => {
      console.log(error);
    });
    
    // eslint-disable-next-line
  }, []);

  return (
    <Layout dataIsLoaded={isLoaded}>
      <div className="Dashboard-ContentBody__Header">
        <h1 className="Dashboard-ContentBody__Header--Title">
          Edit User
        </h1>
      </div>
      <div className="Dashboard-ContentBody__Form">
        <Row>
          <Col md="10">
            < AlertMessage / >
            <Card className="shadow-sm">
              <Card.Body>
                <Form onSubmit={handleSubmit} noValidate>
                  <h5 className="text-secondary mb-3">General details</h5>
                  <Row>
                    <Col md="6">
                      <InputSelect
                        name="role"
                        label="User role"
                        placeholder="Choose a role"
                        options={userRoleOptions}
                        value={values.role}
                        onChange={(event) => handleSelectChange(event, 'role')}
                        errorMsg={errors.role}
                        required={true}
                      />
                    </Col>
                    <Col md="6">
                      <InputSelect
                        name="status"
                        label="Status"
                        placeholder="Choose a status"
                        options={statusOptions}
                        value={values.status}
                        onChange={(event) => handleSelectChange(event, 'status')}
                        errorMsg={errors.status}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <h5 className="text-secondary mt-5 mb-3">User details</h5>
                  <Row>
                    <Col md="6">
                      <InputField
                        type="text"
                        name="name"
                        label="Name"
                        placeholder="User full name"
                        value={values.name || ''}
                        onChange={handleChange}
                        errorMsg={errors.name}
                        required
                      />
                    </Col>
                    <Col md="6">
                      <InputField
                        type="text"
                        name="phone"
                        label="Phone number"
                        maxLength={10}
                        value={values.phone || ''}
                        onChange={handleChange}
                        onKeyDown={handleNumbersOnlyKeyDown}
                        errorMsg={errors.phone}
                        required
                      />
                    </Col>
                    <Col md="12">
                      <InputField
                        type="text"
                        name="email"
                        label="Email address"
                        value={values.email || ''}
                        onChange={handleChange}
                        errorMsg={errors.email}
                        required
                      />
                    </Col>
                  </Row>
                  < FormSubmit
                    type = "submit"
                    variant = "primary"
                    btnText = "Save Changes" 
                  />
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default RoleEdit;